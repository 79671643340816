// import { toPng } from 'html-to-image';
// import React, { useState ,useCallback,useRef, useContext} from 'react';
// import './Catalog.css'; // Import your CSS file
// import Header from './Header';
// import rect1 from './images/rect6.png'
// import PreviewImg from "./images/Preview.png"
// import circle1 from "./images/GOLDEN CIRCLE FRAME.jpg"
// import square from './images/SQUARE FRAME.jpg'
// import square1 from './images/GOLDEN SQUARE RAME.jpg'
// import rect2 from './images/RECTANGLE FEAME(1).jpg'
// import rect7 from './images/rect7.jpg'
// import oval from './images/oval.png'
// import oval1 from './images/OVALGold.png'
// import { AutoTextSize } from 'auto-text-size'
// import Draggable, { DraggableCore } from 'react-draggable';
// import { useScreenshot } from 'use-react-screenshot'
// import AvatarEditor from 'react-avatar-editor' // Both at the same time
// // import Editor from './Editer';
// import Tour from './Tour';
// import Footer from './Footer';
// import { Link, useNavigate } from 'react-router-dom';
// import backgroundImage from './images/Designer2.png';
// import circle from './images/CIRCLE FRAME.jpg'
// import { StyledEditorBlock, TextEditorBlock } from "react-web-editor"
// import { useEffect } from 'react';
// import html2canvas from 'html2canvas-pro';
// import { FeedbackReporter } from "@medanosol/react-feedback-report";
// import domtoimage from 'dom-to-image';
// import axios from 'axios';
// import { CartContext } from './CartContext';
// function App() {
//     const ref = useRef(null)
//     const navigate=useNavigate();
//     const [image10, takeScreenshot] = useScreenshot()
//    const {addToCart}=useContext(CartContext)

//     const [selectedImage3, setSelectedImage3] = useState(null);
//     const [selectedShape, setSelectedShape] = useState("rectangle");
//     const [selectedSize, setSelectedSize] = useState('12x9');
//     const [selectedThickness, setSelectedThickness] = useState('3MM');
//     const [selectedImage2, setSelectedImage2] = useState(null);
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [scale, setScale] = useState(0.8);
//     const parentStyle = { width: 27, height: 20.5 };

//     const [framtext, setFramText] = useState(''); // State for the text input
//     const [color, setColor] = useState('#000000'); // State for the color input
//     //    const [selectedImage, setSelectedImage] = useState(false);
//     const [imageSelected, setImageSelected] = useState(false);
//     const [text, setText] = useState('');
//     const [showTextInput, setShowTextInput] = useState(false);
//     const [position, setPosition] = useState({ x: 0, y: 0 });
//     const [isDraggable, setIsDraggable] = useState(false);
//     const [framTextfontfamilystate, setframTextfontfamilystate] = useState("");
//     const [screenshot, setScreenshot] = useState(null);
//     const [acrylicsize4,setacrylicsize4]=useState([]);
//     const [thicknessdata,setthicknessdata]=useState([]);
//     const [sizeId ,setsizeId]=useState([]);
//     const [thicknessprice,setthicknessprice]=useState();
//     const [sizeprice,setsizeprice]=useState();
//     const [thicknessId,setthicknessId]=useState();
//     const handleSliderChange = (e) => {
//         setScale(e.target.value / 100);
//         console.log(scale)
//         // Convert range value to scale factor (0.2 to 1.0)
//     };
//     const handleTextChange = (e) => {
//         setText(e.target.value);
//     };

//     const captureScreenshot = () => {
//         html2canvas(ref.current,{

//             useCORS: true,
//       scrollX: 0,
//       scrollY: 1200,
//       width: ref.current.scrollWidth, // Capture full width of the element
//       height: ref.current.scrollHeight, // Capture full height of the element
//         }).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             // Store imgData in your store or state
//             console.log(imgData);
//             setScreenshot(imgData); // This is the base64 image
//             // You can save it to a store (Redux, localStorage, etc.)
//           }).then(()=>{
//             handleAddToCart();
//           })
//       };

//     const handleFocus = () => {
//         setIsDraggable(true);
//     };


//     // const captureScreenshot = () => {
//     //   html2canvas(document.body).then((canvas) => {
//     //     const imgData = canvas.toDataURL('image/png');
//     //     setScreenshot(imgData); // Store the screenshot in the state
//     //   });
//     // };


//     const handleCapture = (captureFeedback) => {
//         // Handle the captured feedback data
//         console.log(captureFeedback+"sdfsdfjlkd");
//       };

//     const handlescreenshot = () => {
//         const element = document.getElementById("divtotakescreenshotof")
//         if(!element){
//             return;
//         }
//         html2canvas(element).then((canvas)=>{
//              let image=canvas.toDataURL("image/jpeg")

//              setScreenshot(image)
//              console.log(screenshot)
//         }).catch(err=>{
//             console.error("We can not take the screenshot of your element at this time")
//         })
//     }




//     const handleBlur = () => {
//         setIsDraggable(false);
//     };


//     const handleTextSubmit = (e) => {
//         e.preventDefault();
//         setShowTextInput(false);
//     };
//     const handleTextClick = () => {
//         setShowTextInput(true);
//     };
//     const handleImageClick = (image) => {
//         setSelectedImage(image);
//     };
//     const handleImageUpload = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setSelectedImage2(reader.result);
//                 setImageSelected(true);
//             };
//             reader.readAsDataURL(file);
//         }
//     };

//     const handleDrag = (e, data) => {
//         setPosition({ x: data.x, y: data.y });
//     };
//     const handleShapeChange = (shape) => {
//         setSelectedShape(shape);
//     };
//     const handleShapeClick = (shape) => {
//         setSelectedShape(shape);
//     };
//     const handleSizeChange = (size) => {
//         setSelectedSize(size);

//     };

//     const handleThicknessChange = (thickness,price,id) => {
//         setSelectedThickness(thickness);
//         setthicknessprice(price)
//         setthicknessId(id)
//         setPrice(sizeprice+thicknessprice)
//     };
//     const handleImageClick1 = (imageSrc) => {
//         setSelectedImage2(imageSrc);
//     };
//     const [price, setPrice] = useState(699);

//     const sizePriceMap = {
//         '12x9': 699,
//         '11x11': 799,
//         '16x12': 899,
//         '16x16': 999,
//         '21x15': 1199,
//         '35x23': 1499,
//     };

//     const handleSizeChange1 = (size,sizeid,price) => {
//         setSelectedSize(size);
//         setsizeprice(price)
//         setsizeId(sizeid)
//         setPrice(sizeprice+thicknessprice);
//     };

//     const framTextfontfamily = ["Permanent Marker", "Grey Qo", "Matemasie", "Edu VIC WA NT Beginner", "Bodoni Moda SC"];




//     useEffect(() => {


//        fetchthikness()
//         fetchsize();
//       }, []);

//       // Empty dependency array to run only once on mount

//       useEffect(() => {


//         fetchthikness();

//       }, [sizeId]);

//       const fetchthikness = async () => {
//         const fetchTokenFromLS = () => {
//           return localStorage.getItem('token');
//         };


//         const token = fetchTokenFromLS();

//         if (token) {
//           try {
//             const response2 = await axios.get(`https://api.hirdayam.com/api/getAcrylicThickness?size_id=${sizeId}`, {
//               headers: {
//                 Authorization: `Bearer ${token}`, // Fixed interpolation
//               },
//             });

//             if (response2 && response2.data && response2.data.data) {
//               console.log('Get Acrylic Thikness:', response2);
//               let acrylicThiknessdata =response2.data.data;
//               setthicknessdata(acrylicThiknessdata); // Update state
//               //     if(acrylicsize){

//               console.log( "updated",thicknessdata);

//               // }

//               // Ensure state is updated before logging
//             }

//             if (response2.data.status === true && Array.isArray(response2.data.data)) {
//               // Your logic here
//             } else {
//               console.error('Unexpected response format:', response2.data);
//             }
//           } catch (error) {
//             console.error('Acrylic thikeness item error:', error);
//           }
//         } else {
//           console.error('Token not found in localStorage.');
//         }
//       };



//       const fetchsize = async () => {
//         const fetchTokenFromLS = () => {
//           return localStorage.getItem('token');
//         };


//         const token = fetchTokenFromLS();

//         if (token) {
//           try {
//             const response = await axios.get('https://api.hirdayam.com/api/getAcrylicSize', {
//               headers: {
//                 Authorization: `Bearer ${token}`, // Fixed interpolation
//               },
//             });

//             if (response && response.data && response.data.data) {
//               console.log('Get Acrylic size:', response);
//               let acrylicSizedata =response.data.data;
//               setacrylicsize4(acrylicSizedata); // Update state
//               // if(acrylicsize){

//               console.log( "updated",acrylicsize4);

//               // }

//               // Ensure state is updated before logging
//             }

//             if (response.data.status === true && Array.isArray(response.data.data)) {
//               // Your logic here
//             } else {
//               console.error('Unexpected response format:', response.data);
//             }
//           } catch (error) {
//             console.error('Acrylic size item error:', error);
//           }
//         } else {
//           console.error('Token not found in localStorage.');
//         }
//       };



//       const handleAddToCart = () => {
//         // console.log(getSingleProduct.variations[0]+"new variation")
//         console.log(sizeId)
//         if (true) {
//           const productToAdd = {
//             // id: "",
//             // name: "",
//             // price:"",
//             // image: "",
//             // text:"",
//             // color:"" ,
//             // variation: "",
//             id:null,
//             acrylicsizeid:sizeId,
//             acrylicsizename:selectedSize,
//             acrylicthicknessid:thicknessId,
//             acrylicthicknessname:selectedThickness,
//             acrylicprice:price,
//             acrylicfinalpreview:screenshot,
//           };
//           console.log(productToAdd.acrylicsizename+"new Acylic")

//         //   if(file==null && filteredCard?.product_type=="personalize"){
//         //       alert("Please Select a Image")
//         //   }
//         //   else{
//              addToCart(productToAdd,1);
//           navigate('/cart');
//         //   }

//         }
//       };









//     return (
//         <>

//             <Header />
//             <Tour />



//             <div  className="container ml-1 mt-24 md:mt-10">
//                 <div style={{
//                     backgroundSize: 'cover', // or 'contain' depending on your requirement
//                     backgroundPosition: 'center', // centers the image
//                     backgroundRepeat: 'no-repeat', // prevents the image from repeating
//                 }}>
//                     <h1 className='acrylic'>Acrylic Photo Borders</h1>

//                     <div id="form-id"  className="profile-pictures">
//                         <div  className="shape-content ">
//                             {selectedShape === 'rectangle' && (
//                                 <div className="rectangle-section gap-1 md:gap-6 mt-4" style={{ display: 'flex' }}>

//                                     <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                                     <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />
//                                     <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                                     <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />
//                                     <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                                     <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />
//                                 </div>
//                             )}

//                             {selectedShape === 'square' && (
//                                 <div className="square-section" style={{ display: 'flex' }}>
//                                     <img src={square} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(square)} />
//                                     <img src={square1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(square1)} />

//                                 </div>
//                             )}

//                             {selectedShape === 'circle' && (
//                                 <div className="circle-section" style={{ display: 'flex', gap: '10px' }}>

//                                     <img src={circle} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(circle)} />
//                                     <img src={circle1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(circle1)} />

//                                 </div>
//                             )}

//                             {selectedShape === 'oval' && (
//                                 <div className="oval-section" style={{ display: 'flex' }}>

//                                     <img src={oval} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(oval)} />
//                                     <img src={oval1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(oval1)} />


//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                     <div   className=' w-screen m-auto  md:w-[1140px] ' style={{
//                         backgroundImage: `url(${backgroundImage})`,
//                         backgroundSize: 'cover',
//                         backgroundPosition: 'center',
//                         overflow: "hidden",
//                         backgroundRepeat: 'no-repeat',
//                     }}>
//                     {/* <img src={image}></img> */}
//                         <div   className="preview ">

//                             <div ref={ref} className="image-container">
//                                 <div ref={ref}  className={` ${selectedImage == null ? "block" : "hidden"}`}>

//                                     <img style={{ zIndex: '0' }} className=' z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }} />

//                                     <div onMouseOver={() => { handleFocus() }}
//                                         onMouseOut={handleBlur} className={`relative ${selectedImage2 == null ? "block" : "hidden"}`}>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >
//                                                 <div
//                                                     style={{ transform: `scale(${scale})` ,backgroundImage:`url(${PreviewImg})` , backgroundSize:"contain", backgroundRepeat:"no-repeat", backgroundPosition:"center" }} 
//                                                     className={`shadow-2xl h-96 w-96  cursor-move ${selectedShape === 'oval' ? 'rounded-[80%]' : ''} ${selectedShape === 'roundedSquare' ? 'rounded-[15%]' : ''} ${selectedShape === 'roundedRectangle' ? 'rounded-[10%]' : ''} ${selectedShape === 'circle'?"rounded-[50%]":""} `}
//                                                      src={PreviewImg} 
//                                                     alt="Draggable" 

//                                                > sdsfsd</div>
//                                             </Draggable>
//                                         ) : (
//                                             <img
//                                                 style={{
//                                                     transform: `scale(${scale})`, position: " relative",  // Positioning to match draggable
//                                                     left: `${position.x}`,
//                                                     top: `${position.y}`,
//                                                 }}
//                                                 className={`shadow-2xl  ${selectedShape === 'oval' ? 'rounded-[80%]' : ''} ${selectedShape === 'roundedSquare' ? 'rounded-[15%]' : ''} ${selectedShape === 'roundedRectangle' ? 'rounded-[10%]' : ''} ${selectedShape === 'circle'?"rounded-[50%]":""} `}
//                                                 src={PreviewImg}
//                                                 alt="Not Draggable"
//                                             />
//                                         )}
//                                         <Draggable
//                                             defaultPosition={{ x: -76, y: -44 }}
//                                         // onDrag={handleDrag}


//                                         >
//                                             <div className=' absolute text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ zIndex: "3", color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>
//                                         {/* <div  className=' text-3xl text-white  border px-3 py-4  bg-black/30 rounded-md  absolute top-[30%] left-[35%]'>PREVIEW</div> */}

//                                     </div>
//                                 </div>
//                                 {selectedShape === 'rectangle' && selectedImage && (
//                                     <div ref={ref} className=' relative'>
//                                         <img className=' h-20 w-20' style={{ zIndex: "1" }} src={selectedImage}
//                                             style={{
//                                                 width: '100%', // Adjust width as needed
//                                                 height: 'auto'
//                                                 , position: "relative",
//                                                 // Maintain aspect ratio
//                                             }}></img>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <div onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' absolute z-0 p-10 cursor-move top-0 h-96 w-96 ' src={selectedImage2} style={{ transform: `scale(${scale})`, backgroundImage:`url(${selectedImage2})` }}  >

//                                                     </div>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' absolute p cursor-move z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>

//                                             )
//                                         }
//                                         {/* <p className=' relative text-black top-0 left-0'>Text</p> */}
//                                         <Draggable
//                                             defaultPosition={{ x: -76, y: -44 }}


//                                         >
//                                             <div className=' absolute text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ zIndex: "3", color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>
//                                     </div>
//                                 )}
//                                 {selectedShape === 'square' && selectedImage && (
//                                     <div className=' relative'>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' absolute cursor-move z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' absolute cursor-move z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>

//                                             )
//                                         }
//                                         <img className='z-0' src={selectedImage}></img>

//                                         <Draggable
//                                             defaultPosition={{ x: -76, y: -44 }}
//                                         // onDrag={handleDrag}


//                                         >
//                                             <div className=' absolute text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ zIndex: "3", color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>

//                                     </div>
//                                 )}
//                                 {selectedShape === 'circle' && selectedImage && (
//                                     <div className=' relative'>
//                                         <img className='z-0' src={selectedImage}></img>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' rounded-full cursor-move absolute z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' rounded-full cursor-move absolute z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>

//                                             )
//                                         }
//                                         <Draggable
//                                             defaultPosition={{ x: -76, y: -44 }}
//                                         // onDrag={handleDrag}


//                                         >
//                                             <div className=' absolute text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ zIndex: "3", color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>
//                                     </div>
//                                 )}
//                                 {selectedShape === 'oval' && selectedImage && (
//                                     <div className=' relative'>
//                                         <img className='z-0' src={selectedImage}></img>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' ronded-full absolute cursor-move z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur} width={"100px"} height={"600px"} style={{ zIndex: '2' }} className=' rounded-full absolute z-0 cursor-move top-0 h-20 w-20 ' src={selectedImage2} style={{ transform: `scale(${scale})` }}  ></img>

//                                             )
//                                         }

//                                         <Draggable
//                                             defaultPosition={{ x: -76, y: -44 }}
//                                         // onDrag={handleDrag}


//                                         >
//                                             <div className=' absolute text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ zIndex: "3", color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>

//                                     </div>
//                                 )}
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//                 <div className="options">


//                     <div className="upload-button my-4">
//                         <div>



//                             <button className="  rounded-md text-black relative" onClick={() => document.getElementById('my_modal_3').showModal()}>

//                                 🔍 Zoom

//                             </button>
//                             <dialog id="my_modal_3" className="modal">
//                                 <div className="modal-box">
//                                     <form method="dialog">
//                                         {/* if there is a button in form, it will close the modal */}
//                                         <button style={{ background: "transparent", color: "black" }} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-0 pb-20 m-20 pl-20">✕</button>
//                                     </form>
//                                     <div className=' flex items-center gap-7'>
//                                         -
//                                         <input
//                                             type="range"
//                                             min="20"
//                                             max="100"
//                                             className="range range-primary h-3"
//                                             value={scale * 100} // Convert scale factor back to percentage for the slider
//                                             onChange={handleSliderChange}
//                                         /> +
//                                     </div>
//                                 </div>
//                                 <form method="dialog" className="modal-backdrop">
//                                     <button style={{ color: "transparent", background: "transparent" }}>close</button>
//                                 </form>
//                             </dialog>


//                         </div>
//                         <button className="option rounded-md" onClick={() => document.getElementById('fileInput').click()}>
//                             🖼️   Select Photo
//                         </button>


//                         {/* You can open the modal using document.getElementById('ID').showModal() method */}
//                         <button className="text rounded-md" onClick={() => document.getElementById('my_modal_4').showModal()}>
//                             📝 Text
//                         </button>

//                         <dialog id="my_modal_4" className="modal">
//                             <div className="modal-box">
//                                 <form method="dialog">
//                                     {/* if there is a button in form, it will close the modal */}
//                                     <button style={{ background: "transparent", color: "black" }} className="btn  btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
//                                 </form>

//                                 <div className='w-full'>
//                                 <label  className=' text-nowrap w-full inline-block text-start'>Add Text</label><br></br>
//                                 <input
//                                     type='text'
//                                     className='border w-72 md:w-96 relative left-0 md:mr-20 px-3 my-4 py-2 rounded-md'
//                                     placeholder='Enter Your Text here'
//                                     value={framtext} // Bind the state to the input value
//                                     onChange={(e) => setFramText(e.target.value)} // Update state on change
//                                 />
//                                 <label className=' text-nowrap  w-full inline-block text-start'>Text Color</label><br></br>
//                                 <div className=' flex'>
//                                      <input

//                                     type='color'
//                                     className='border w-20  relative left-0 inline-block h-8  md:mr-96  px-3 my-4  rounded-md'
//                                     placeholder='Enter Your Text here'
//                                     value={color} // Bind the state to the input value
//                                     onChange={(e) => setColor(e.target.value)} // Update state on change
//                                 />
//                                 </div>


//                                 <div className={`${framtext == "" ? "hidden" : "block"}`}>
//                                     <label  className=' text-nowrap w-full inline-block text-start'>Text Style</label><br></br>
//                                     {
//                                         framTextfontfamily.map((text, index) => (
//                                             <form method="dialog">
//                                                 <p className='border rounded-md my-3 text-2xl py-4 cursor-pointer' onClick={() => { setframTextfontfamilystate(text) }} key={index} style={{ fontFamily: `${text}` ,color: `${color}`, }}>{framtext}</p>
//                                             </form>
//                                         ))
//                                     }


//                                 </div>
//                                 <div>

//                                 </div>
//                                  </div>
//                                 <form method="dialog" className={`${framtext == "" ? "hidden" : "block"}`}>
//                                     {/* if there is a button in form, it will close the modal */}
//                                     <button style={{ background: "", color: "" }} className="btn text-white   w-full h-full  btn-outline btn-primar ">Save</button>
//                                 </form>
//                             </div>
//                         </dialog>
//                         <input
//                             type="file"
//                             id="fileInput"
//                             style={{ display: 'none' }}
//                             accept="image/*"
//                             onChange={handleImageUpload}
//                         />
//                     </div>

//                     <div className="shapes">
//                         <h3 className='acrylic'>Acrylic Photo Shapes</h3>
//                         <div className="shape-options pl-10  mt-2">

//                             <svg
//                                 viewBox="0 0 600 400"
//                                 className={` shape-svg1 ${selectedShape === 'rectangle' ? 'border-blue' : ''} `}
//                                 onClick={() => handleShapeClick('rectangle')}
//                             >
//                                 <rect x="10" y="10" width="480" height="280" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>
//                             <svg
//                                 viewBox="0 0 400 400"
//                                 className={`shape-svg2 ${selectedShape === 'square' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('square')}
//                             >
//                                 <rect x="10" y="10" width="280" height="280" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>


//                             <svg
//                                 viewBox="0 0 200 200"
//                                 className={`shape-svg3 ${selectedShape === 'circle' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('circle')}
//                                 width="100"
//                             >
//                                 <circle cx="100" cy="100" r="80" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>


//                             <svg
//                                 viewBox="0 0 400 400"
//                                 className={`shape-svg4 ${selectedShape === 'oval' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('oval')}
//                             >
//                                 <ellipse cx="200" cy="200" rx="200" ry="100" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>
//                             <svg
//                                 viewBox="0 0 400 400"
//                                 className={`shape-svg5 ${selectedShape === 'roundedSquare' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('roundedSquare')}
//                             >
//                                 <rect
//                                     x="50"
//                                     y="50"
//                                     width="300"
//                                     height="300"
//                                     rx="50"
//                                     ry="50"
//                                     fill="#c1995d"
//                                     stroke=""
//                                     strokeWidth="5"
//                                 />
//                             </svg>
//                             <svg
//                                 viewBox="0 0 600 400"
//                                 className={`shape-svg6 ${selectedShape === 'roundedRectangle' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('roundedRectangle')}
//                             >
//                                 <rect
//                                     x="50"
//                                     y="50"
//                                     width="500"
//                                     height="300"
//                                     rx="50"
//                                     ry="50"
//                                     fill="#c1995d"
//                                     stroke=""
//                                     strokeWidth="5"
//                                 />
//                             </svg>

//                         </div>

//                     </div>
//                     <div className="size-options">
//                         <br />
//                         <br />
//                         <div className='size'>
//                             <h3 className='acrylic1 ml-10 md:ml-0 my-3'>Size (Inch): {selectedSize}</h3>
//                           <div className='size-buttons'> { acrylicsize4.length>0?(acrylicsize4.map((item,index)=>{
//                             return(
//                                 <div   key={index}>
//                                 <button onClick={() => handleSizeChange1(item.size,item._id,item.price)} className={`size-button ${selectedSize === item.size ? 'active' : ''} rounded-md  ${selectedSize === item.size ? '' : 'hover:ring'}`}>{item.size}</button>

//                                 </div>


//                             );

//                            })):("sdfk")
//                            }
//                            </div>
//                             {/* <div className="size-buttons">
//                                 <button onClick={() => handleSizeChange1('11x11')} className={`size-button ${selectedSize === '11x11' ? 'active' : ''} rounded-md  ${selectedSize === '11x11' ? '' : 'hover:ring'}`}>11x11</button>
//                                 <button onClick={() => handleSizeChange1('16x12')} className={`size-button ${selectedSize === '16x12' ? 'active' : ''} rounded-md  ${selectedSize === '16x12' ? '' : 'hover:ring'}`}>16x12</button>
//                                 <button onClick={() => handleSizeChange1('16x16')} className={`size-button ${selectedSize === '16x16' ? 'active' : ''} rounded-md  ${selectedSize === '16x16' ? '' : 'hover:ring'}`}>16x16</button>
//                                 <button onClick={() => handleSizeChange1('21x15')} className={`size-button ${selectedSize === '21x15' ? 'active' : ''} rounded-md  ${selectedSize === '21x15' ? '' : 'hover:ring'}`}>21x15</button>
//                                 <button onClick={() => handleSizeChange1('35x23')} className={`size-button ${selectedSize === '35x23' ? 'active' : ''} rounded-md  ${selectedSize === '35x23' ? '' : 'hover:ring'}`}>35x23</button>
//                             </div> */}
//                         </div>

//                     </div>

//                     <div className="thickness-options mr-12  md:mr-48">
//                         <h3 className='acrylic1  my-3 '>Thickness: {selectedThickness}</h3>

//                         <div className='thickness-buttons'> { thicknessdata.length>0?(thicknessdata.map((item,index)=>{
//                             return(
//                                 <div   key={index}>
//                                 {/* <button onClick={() => handleSizeChange1(item.size,item._id)} className={`size-button ${selectedSize === item.size ? 'active' : ''} rounded-md  ${selectedSize === item.size ? '' : 'hover:ring'}`}>{item.size}</button> */}
//                              <button onClick={() => {handleThicknessChange(item.thickness,item.price,item._id)}} className={`thickness-button rounded-md ${selectedThickness === item.thickness ? 'active' : ''} ${selectedThickness === item.thickness ? '' : 'hover:ring'}`}>{item.thickness}</button>

//                                 </div>


//                             );

//                            })):(<div>skdf</div>)
//                            }
//                            </div>
//                         {/* <div className="thickness-buttons">
//                             <button onClick={() => handleThicknessChange('3MM')} className={`thickness-button rounded-md ${selectedThickness === '3MM' ? 'active' : ''} ${selectedThickness === '3MM' ? '' : 'hover:ring'}`}>3MM</button>
//                             <button onClick={() => handleThicknessChange('5MM')} className={`thickness-button rounded-md ${selectedThickness === '5MM' ? 'active' : ''} ${selectedThickness === '5MM' ? '' : 'hover:ring'}`}>5MM</button>
//                             <button onClick={() => handleThicknessChange('8MM')} className={`thickness-button rounded-md ${selectedThickness === '8MM' ? 'active' : ''} ${selectedThickness === '5MM' ? '' : 'hover:ring'}`}>8MM</button>
//                         </div> */}
//                     </div>
//                     <hr className='border-dashed border-[1px] border-gray-200 mb-2 w-screen md:w-[1200px]' />

//                     <div className="price1 text-sm">
//                         <span className='text-xl'>₹{price} <span className=' line-through text-gray-300'>₹{price + 1000}</span></span>
//                         <p className='mt-6' style={{ fontWeight: "300" }}>  Photo quality for <span style={{ fontWeight: "600" }}>{selectedSize}</span> is <span className='text-green-500'>Good</span></p>
//                         <p><span style={{ fontWeight: "300" }}>Quick mount:</span> <span className='text-bold text-balance'>Hridayam® Adhesive hooks (Included)</span></p>
//                     </div>

//                     {/* <Link to='/checkout'> */}
//                      <button onClick={captureScreenshot} className="buy-now rounded-md px-20">BUY IT NOW</button>
//                      {/* </Link> */}

//                     <div style={{ maxWidth: '60%', margin: '-30px auto'}}>

//                     </div>
//                 </div>
//             </div>



//             <img src={screenshot}></img>
//               <div>
//             </div>
//             <Footer />

//         </>
//     );
// }

// export default App;

// import { toPng } from 'html-to-image';
// import React, { useState, useCallback, useRef, useContext } from 'react';
// import './Catalog.css'; // Import your CSS file
// import Header from './Header';
// import rect1 from './images/rect6.png'
// import PreviewImg from "./images/Preview.png"
// import circle1 from "./images/GOLDEN CIRCLE FRAME.jpg"
// import square from './images/Square1.png'
// import square1 from './images/Square.png'
// import rect2 from './images/RECTANGLE FEAME(1).jpg'
// import rect7 from './images/rect7.jpg'
// import oval from './images/oval.png'
// import oval1 from './images/OVALGold.png'
// import { AutoTextSize } from 'auto-text-size'
// import Draggable, { DraggableCore } from 'react-draggable';
// import { useScreenshot } from 'use-react-screenshot'
// import AvatarEditor from 'react-avatar-editor' // Both at the same time
// // import Editor from './Editer';
// import Tour from './Tour';
// import Footer from './Footer';
// import { Link, useNavigate } from 'react-router-dom';
// import backgroundImage from './images/Designer2.png';
// import circle from './images/CIRCLE FRAME.jpg'
// import { StyledEditorBlock, TextEditorBlock } from "react-web-editor"
// import { useEffect } from 'react';
// import html2canvas from 'html2canvas-pro';
// import { FeedbackReporter } from "@medanosol/react-feedback-report";
// import domtoimage from 'dom-to-image';
// import axios from 'axios';
// import { CartContext } from './CartContext';
// function App() {
//     const ref = useRef(null)
//     const navigate = useNavigate();
//     const [image10, takeScreenshot] = useScreenshot()
//     const { addToCart } = useContext(CartContext)

//     const [selectedImage3, setSelectedImage3] = useState(null);
//     const [selectedShape, setSelectedShape] = useState("rectangle");
//     const [selectedSize, setSelectedSize] = useState('12x9');
//     const [selectedThickness, setSelectedThickness] = useState('3MM');
//     const [selectedImage2, setSelectedImage2] = useState(null);
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [scale, setScale] = useState(0.8);
//     const parentStyle = { width: 27, height: 20.5 };

//     const [framtext, setFramText] = useState(''); // State for the text input
//     const [color, setColor] = useState('#000000'); // State for the color input
//     //    const [selectedImage, setSelectedImage] = useState(false);
//     const [imageSelected, setImageSelected] = useState(false);
//     const [text, setText] = useState('');
//     const [showTextInput, setShowTextInput] = useState(false);
//     const [position, setPosition] = useState({ x: -200, y: 0 });
//     const [isDraggable, setIsDraggable] = useState(false);

//     const [framTextfontfamilystate, setframTextfontfamilystate] = useState("");
//     const [screenshot, setScreenshot] = useState(null);
//     const [acrylicsize4, setacrylicsize4] = useState([]);
//     const [thicknessdata, setthicknessdata] = useState([]);
//     const [sizeId, setsizeId] = useState([]);
//     const [thicknessprice, setthicknessprice] = useState();
//     const [sizeprice, setsizeprice] = useState();
//     const [thicknessId, setthicknessId] = useState();
//     const [Acrylicsize, setAcrylicsize] = useState(null);


//     const handleSliderChange = (e) => {
//         setScale(e.target.value / 100);
//         console.log(scale)
//         // Convert range value to scale factor (0.2 to 1.0)
//     };
//     const handleTextChange = (e) => {
//         setText(e.target.value);
//     };
//     function base64ToFile(base64String, filename) {
//         // Decode the base64 string
//         let arr = base64String.split(','),
//             mime = arr[0].match(/:(.*?);/)[1],
//             bstr = atob(arr[1]), // atob() decodes a base64 string
//             n = bstr.length,
//             u8arr = new Uint8Array(n);

//         while (n--) {
//             u8arr[n] = bstr.charCodeAt(n);
//         }

//         // Create a File object
//         return new File([u8arr], filename, { type: mime });
//     }



//     const captureScreenshot = () => {
//         html2canvas(ref.current, {

//             useCORS: true,
//             scrollX: 0,
//             scrollY: 1200,
//             width: ref.current.scrollWidth, // Capture full width of the element
//             height: ref.current.scrollHeight, // Capture full height of the element
//         }).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             let imageFile = base64ToFile(imgData, "image.jpg");
//             // Store imgData in your store or state
//             console.log(imageFile);
//             setScreenshot(imageFile); // This is the base64 image
//             // You can save it to a store (Redux, localStorage, etc.)
//         })
//     };

//     const handleFocus = () => {
//         setIsDraggable(true);
//     };


//     // const captureScreenshot = () => {
//     //   html2canvas(document.body).then((canvas) => {
//     //     const imgData = canvas.toDataURL('image/png');
//     //     setScreenshot(imgData); // Store the screenshot in the state
//     //   });
//     // };

//     function base64ToFile(base64String, filename) {
//         // Decode the base64 string
//         let arr = base64String.split(','),
//             mime = arr[0].match(/:(.*?);/)[1],
//             bstr = atob(arr[1]), // atob() decodes a base64 string
//             n = bstr.length,
//             u8arr = new Uint8Array(n);

//         while (n--) {
//             u8arr[n] = bstr.charCodeAt(n);
//         }

//         // Create a File object
//         return new File([u8arr], filename, { type: mime });
//     }


//     const handleCapture = (captureFeedback) => {
//         // Handle the captured feedback data
//         console.log(captureFeedback + "sdfsdfjlkd");
//     };

//     const handlescreenshot = () => {
//         const element = document.getElementById("divtotakescreenshotof")
//         if (!element) {
//             return;
//         }
//         html2canvas(element).then((canvas) => {
//             let image = canvas.toDataURL("image/jpeg")
//             let imageFile = base64ToFile(image, "image.jpg");
//             setScreenshot(imageFile)
//             console.log(screenshot)
//         }).catch(err => {
//             console.error("We can not take the screenshot of your element at this time")
//         })
//     }

//     useEffect(() => {

//         const fetchData = async () => {
//             const fetchTokenFromLS = () => {
//                 return localStorage.getItem('token');
//             };

//             const fetchOrderId = () => {
//                 return localStorage.getItem('orderid');
//             };

//             const orderid = fetchOrderId();
//             const token = fetchTokenFromLS();

//             if (token) {
//                 try {
//                     const response = await axios.get('https://api.hirdayam.com/api/getAcrylicSize', {
//                         headers: {
//                             Authorization: `Bearer ${token}`, // Fixed interpolation
//                         },
//                     });

//                     if (response && response.data && response.data.data) {
//                         console.log('Get Acrylic size:', response);
//                         // let data=response;
//                         // setAcrylicsize(data); // Update state
//                         setsizeId(response.data.data[0]._id)
//                         setsizeprice(response.data.data[0].price)


//                         console.log(Acrylicsize + 'new');



//                     }

//                     if (response.data.status === true && Array.isArray(response.data.data)) {
//                         // Your logic here
//                     } else {
//                         console.error('Unexpected response format:', response.data);
//                     }
//                 } catch (error) {
//                     console.error('Acrylic size item error:', error);
//                 }
//             } else {
//                 console.error('Token not found in localStorage.');
//             }
//         };

//         fetchData();
//     }, []);



//     const handleBlur = () => {
//         setIsDraggable(false);
//     };


//     const handleTextSubmit = (e) => {
//         e.preventDefault();
//         setShowTextInput(false);
//     };
//     const handleTextClick = () => {
//         setShowTextInput(true);
//     };
//     const handleImageClick = (image) => {
//         setSelectedImage(image);
//     };
//     const handleImageUpload = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setSelectedImage2(reader.result);
//                 setImageSelected(true);
//             };
//             reader.readAsDataURL(file);
//         }
//     };

//     const handleDrag = (e, data) => {
//         setPosition({ x: data.x, y: data.y });
//     };
//     const handleShapeChange = (shape) => {
//         setSelectedShape(shape);
//     };
//     const handleShapeClick = (shape) => {
//         setSelectedShape(shape);
//     };
//     const handleSizeChange = (size) => {
//         setSelectedSize(size);

//     };

//     const handleThicknessChange = (thickness, price, id) => {
//         setSelectedThickness(thickness);
//         setthicknessprice(price)
//         setthicknessId(id)
//         // setPrice(sizeprice+thicknessprice)
//     };
//     const handleImageClick1 = (imageSrc) => {
//         setSelectedImage2(imageSrc);
//     };
//     const [price, setPrice] = useState(0);

//     const sizePriceMap = {
//         '12x9': 699,
//         '11x11': 799,
//         '16x12': 899,
//         '16x16': 999,
//         '21x15': 1199,
//         '35x23': 1499,
//     };

//     const handleSizeChange1 = (size, sizeid, price) => {
//         setSelectedSize(size);
//         setsizeprice(price)
//         setsizeId(sizeid)
//         setPrice(sizeprice + thicknessprice);
//     };

//     const framTextfontfamily = ["Permanent Marker", "Grey Qo", "Matemasie", "Edu VIC WA NT Beginner", "Bodoni Moda SC"];




//     useEffect(() => {



//         fetchsize();
//         // fetchthikness();
//     }, []);

//     // Empty dependency array to run only once on mount

//     useEffect(() => {


//         fetchthikness();


//     }, [sizeId]);

//     const fetchthikness = async () => {



//             try {
//                 const response2 = await axios.get(`https://api.hirdayam.com/api/getAcrylicThickness?size_id=${sizeId}`, {
//                     headers: {
//                         // Authorization: `Bearer ${token}`, // Fixed interpolation
//                     },
//                 });

//                 if (response2 && response2.data && response2.data.data) {
//                     console.log('Get Acrylic Thikness:', response2);
//                     let acrylicThiknessdata = response2.data.data;
//                     setthicknessdata(acrylicThiknessdata);
//                     setthicknessprice(response2.data.data[0].price)

//                     setSelectedThickness(response2.data.data[0].thickness) // Update state
//                     //     if(acrylicsize){
//                     console.log(response2.data.data[0].thickness+"thickness")
//                         setPrice(sizeprice + thicknessprice);
//                     console.log("updated", thicknessdata);

//                     // }

//                     // Ensure state is updated before logging
//                 }

//                 if (response2.data.status === true && Array.isArray(response2.data.data)) {
//                     // Your logic here
//                 } else {
//                     console.error('Unexpected response format:', response2.data);
//                 }
//             } catch (error) {
//                 console.error('Acrylic thikeness item error:', error);
//             }

//     };



//     const fetchsize = async () => {
//         const fetchTokenFromLS = () => {
//             return localStorage.getItem('token');
//         };




//             try {
//                 const response = await axios.get('https://api.hirdayam.com/api/getAcrylicSize', {
//                     headers: {
//                        // Authorization: `Bearer ${token}`, // Fixed interpolation
//                     },
//                 });

//                 if (response && response.data && response.data.data) {
//                     console.log('Get Acrylic size:', response);
//                     let acrylicSizedata = response.data.data;
//                     setacrylicsize4(acrylicSizedata); // Update state
//                     // if(acrylicsize){
//                     setSelectedSize(response.data.data[0].size);
//                     setsizeId(response.data.data[0]._id)
//                     console.log("updated", acrylicsize4);
//                     setsizeprice(response.data.data[0].price)

//                     // }

//                     // Ensure state is updated before logging
//                 }

//                 if (response.data.status === true && Array.isArray(response.data.data)) {
//                     // Your logic here
//                 } else {
//                     console.error('Unexpected response format:', response.data);
//                 }
//             } catch (error) {
//                 console.error('Acrylic size item error:', error);
//             }
//         // } else {
//         //     console.error('Token not found in localStorage.');
//         // }
//     };




//     const handleAddToCart = () => {
//         // console.log(getSingleProduct.variations[0]+"new variation")
//         console.log(sizeId)
//         if (true) {
//           const productToAdd = {
//             // id: "",
//             // name: "",
//             // price:"",
//             // image: "",
//             // text:"",
//             // color:"" ,
//             // variation: "",



//             acrylicsizeid:sizeId,
//             acrylicsizename:selectedSize,
//             acrylicthicknessid:thicknessId,
//             acrylicthicknessname:selectedThickness,
//             acrylicprice:sizeprice+thicknessprice,
//             acrylicfinalpreview:screenshot,

//           };
//           console.log(productToAdd.acrylicsizename+"new Acylic")

//             //   if(file==null && filteredCard?.product_type=="personalize"){
//             //       alert("Please Select a Image")
//             //   }
//             //   else{

//             if (screenshot != null) {


//                 addToCart(productToAdd, 1);
//                 navigate('/cart');
//             }
//             //   }

//         }
//     };


//     // useEffect(() => {
//     //  handleAddToCart();
//     // }, [screenshot])

//     if (screenshot != null) {
//         handleAddToCart();
//     }







//     return (
//         <>

//             <Header />
//             <Tour />



//             <div className="container19 ml-1 mt-24 md:mt-10">
//                 <div style={{
//                     backgroundSize: 'cover', // or 'contain' depending on your requirement
//                     backgroundPosition: 'center', // centers the image
//                     backgroundRepeat: 'no-repeat', // prevents the image from repeating
//                 }}>
//                 {/* <h1 className='acrylic'>Acrylic Photo Frames</h1> */}

//                     {/* <div id="form-id" className="profile-pictures">
//                         <div className="shape-content ">
//                             {selectedShape === 'rectangle' && (
//                                 <div className="rectangle-section gap-1 md:gap-6 mt-4" style={{ display: 'flex' }}>

//                                     <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                                     <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />
//                                     <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                                     <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />
//                                     <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                                     <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />
//                                 </div>
//                             )}

//                             {selectedShape === 'square' && (
//                                 <div className="square-section" style={{ display: 'flex' }}>
//                                     <img src={square} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(square)} />
//                                     <img src={square1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(square1)} />

//                                 </div>
//                             )}

//                             {selectedShape === 'circle' && (
//                                 <div className="circle-section" style={{ display: 'flex', gap: '10px' }}>

//                                     <img src={circle} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(circle)} />
//                                     <img src={circle1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(circle1)} />

//                                 </div>
//                             )}

//                             {selectedShape === 'oval' && (
//                                 <div className="oval-section" style={{ display: 'flex' }}>

//                                     <img src={oval} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(oval)} />
//                                     <img src={oval1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(oval1)} />


//                                 </div>
//                             )}
//                         </div>
//                     </div> */}
//                     <div className=' w-screen m-auto  md:w-[1140px] ' style={{
//                         backgroundImage: `url(${backgroundImage})`,
//                         backgroundSize: 'cover',
//                         backgroundPosition: 'center',
//                         overflow: "hidden",
//                         backgroundRepeat: 'no-repeat',
//                     }}>
//                         <div className="acrylic-photo-borders" style={{
//         display: 'flex',
//         flexDirection: 'column',  
//         position: 'absolute',    
//         left: '15rem',
//         height:'21.5rem', 
//         borderRadius:'5px',         
//         top: '270px',             
//         gap: '10px'              
//     }}>
//         {selectedShape === 'rectangle' && (
//             <div className="rectangle-section gap-1 md:gap-6 mt-4" style={{ display: 'flex', flexDirection: 'column' }}>
//                 <img src={rect1} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect1)} />
//                 <img src={rect7} style={{ height: '60px', width: '60px' }} onClick={() => handleImageClick(rect7)} />

//             </div>
//         )}

//         {selectedShape === 'square' && (
//             <div className="square-section" style={{ display: 'flex', flexDirection: 'column' }}>
//                 <img src={square} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(square)} />
//                 <img src={square1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(square1)} />
//             </div>
//         )}

//         {selectedShape === 'circle' && (
//             <div className="circle-section" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//                 <img src={circle} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(circle)} />
//                 <img src={circle1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(circle1)} />
//             </div>
//         )}

//         {selectedShape === 'oval' && (
//             <div className="oval-section" style={{ display: 'flex', flexDirection: 'column' }}>
//                 <img src={oval} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(oval)} />
//                 <img src={oval1} style={{ height: '60px', width: '60px', marginTop: '5px' }} onClick={() => handleImageClick(oval1)} />
//             </div>
//         )}
//     </div>
//                         {/* <img src={image}></img> */}
//                         <div className="preview22">

//                             <div ref={ref} className="image-container12">
//                                 <div ref={ref} className={` ${selectedImage == null ? "block" : "hidden"}`}>

//                                     <img onMouseOver={() => { handleFocus() }}
//                                         onMouseOut={handleBlur}  className=' z-0 top-0 h-20 w-20 ' src={selectedImage2} style={{zIndex: '0', transform: `scale(${scale})` }} />

//                                     <div
//                                         onMouseOver={() => { handleFocus() }}
//                                         onMouseOut={handleBlur}
//                                         className={`relative ${selectedImage2 == null ? "block" : "hidden"}`}>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >
//                                                 <div
//                                                     style={{ transform: `scale(${scale})`, backgroundImage: `url(${PreviewImg})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}
//                                                     className={`shadow-2xl h-96 w-96 z-40  cursor-move ${selectedShape === 'oval' ? 'rounded-[80%]' : ''} ${selectedShape === 'roundedSquare' ? 'rounded-[15%]' : ''} ${selectedShape === 'roundedRectangle' ? 'rounded-[10%]' : ''} ${selectedShape === 'circle' ? "rounded-[50%]" : ""} `}
//                                                     src={PreviewImg}
//                                                     alt="Draggable"

//                                                 ></div>
//                                             </Draggable>
//                                         ) : (
//                                             <img
//                                                 style={{
//                                                     transform: `scale(${scale})`, position: " relative",  // Positioning to match draggable
//                                                     left: `${position.x}`,
//                                                     top: `${position.y}`,
//                                                 }}
//                                                 className={`shadow-2xl   ${selectedShape === 'oval' ? 'rounded-[80%]' : ''} ${selectedShape === 'roundedSquare' ? 'rounded-[15%]' : ''} ${selectedShape === 'roundedRectangle' ? 'rounded-[10%]' : ''} ${selectedShape === 'circle' ? "rounded-[50%]" : ""} `}
//                                                 src={PreviewImg}
//                                                 alt="Not Draggable"
//                                             />
//                                         )}
//                                         <Draggable

//                                             defaultPosition={{ x: -76, y: -44 }}
//                                         // onDrag={handleDrag}


//                                         >
//                                             <div className=' absolute z-0 text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ zIndex: "3", color: `${color}`, transform: `scale($scale)`, transformOrigin: 'center', fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>
//                                         {/* <div  className=' text-3xl text-white  border px-3 py-4  bg-black/30 rounded-md  absolute top-[30%] left-[35%]'>PREVIEW</div> */}

//                                     </div>
//                                 </div>
//                                 {selectedShape === 'rectangle' && selectedImage && (
//                                     <div ref={ref} className=' relative'>
//                                         <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                      className=' h-20 w-20 z-20'  src={selectedImage}
//                                             style={{
//                                                 zIndex: "1",
//                                                 width: '100%', // Adjust width as needed
//                                                 height: 'auto'
//                                                 , position: "relative",
//                                                 // Maintain aspect ratio
//                                             }}></img>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 scale={scale}
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <div
//                                                     onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                     className=' absolute z-40 p-10 cursor-move top-0 h-[600px] w-[800px] border-8 ' src={selectedImage2}
//                                                     style={{ transform: `scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                 </div>
//                                             </Draggable>
//                                         )
//                                             : (


//                                                 <Draggable scale={scale} position={position} onDrag={handleDrag}>

//                                                     <div

//                                                         className=' absolute z-0 p-10 cursor-move top-0 h-[600px] w-[800px] border-8 ' src={selectedImage2}
//                                                         style={{ translate: `(${position.x}px, ${position.y}px) scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                     </div>
//                                                 </Draggable>


//                                             )
//                                         }
//                                         {/* <p className=' relative text-black top-0 left-0'>Text</p> */}
//                                         <Draggable
//                                             onMouseOver={() => { handleFocus() }}
//                                             onMouseOut={handleBlur}
//                                             defaultPosition={{ x: -76, y: -44 }}


//                                         >
//                                             <div className=' absolute z-40 text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>
//                                     </div>
//                                 )}
//                                 {selectedShape === 'square' && selectedImage && (
//                                     <div className=' relative bg-white'>
//                                     <div className='h-full w-full bg-white'>
//                                     {/* <img className='z-50' src={selectedImage}></img> */}
//                                     <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                      className=' py-20  z-20'  src={selectedImage}
//                                             style={{
//                                                 zIndex: "1",
//                                                 width: '100%', // Adjust width as needed
//                                                 height: '100%'
//                                                 , position: "relative",
//                                                 // Maintain aspect ratio
//                                             }}></img>
//                                             </div>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 scale={scale}
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <div
//                                                     onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                     className=' absolute z-40 p-10 cursor-move top-0 h-[600px] w-[800px] border-8 ' src={selectedImage2}
//                                                     style={{ transform: `scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                 </div>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <Draggable scale={scale} position={position} onDrag={handleDrag}>

//                                                     <div

//                                                         className=' absolute z-0 p-10 cursor-move top-0 h-[600px] w-[800px] border-8 ' src={selectedImage2}
//                                                         style={{ translate: `(${position.x}px, ${position.y}px) scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                     </div>
//                                                 </Draggable>

//                                             )
//                                         }

//                                         <Draggable
//                                             onMouseOver={() => { handleFocus() }}
//                                             onMouseOut={handleBlur}
//                                             defaultPosition={{ x: -76, y: -44 }}


//                                         >
//                                             <div className=' absolute z-40 text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>

//                                     </div>
//                                 )}
//                                 {selectedShape === 'circle' && selectedImage && (
//                                     <div className=' relative'>
//                                         {/* <img className='z-0' src={selectedImage}></img> */}
//                                         <div className='h-full w-full bg-white py-20'>
//                                         <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                      className=' h-20 w-20 z-20'  src={selectedImage}
//                                             style={{
//                                                 zIndex: "1",
//                                                 width: '100%', // Adjust width as needed
//                                                 height: 'auto'
//                                                 , position: "relative",
//                                                 // Maintain aspect ratio
//                                             }}></img>
//                                             </div>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 scale={scale}
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <div
//                                                     onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                     className=' absolute z-40 p-10 cursor-move top-0 h-[600px] w-[800px] border-8 ' src={selectedImage2}
//                                                     style={{ transform: `scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                 </div>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <Draggable scale={scale} position={position} onDrag={handleDrag}>

//                                                     <div

//                                                         className=' absolute z-0 p-10 rounded-full cursor-move top-0 h-[600px] w-[800px]  ' src={selectedImage2}
//                                                         style={{ translate: `(${position.x}px, ${position.y}px) scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                     </div>
//                                                 </Draggable>

//                                             )
//                                         }
//                                         <Draggable
//                                             onMouseOver={() => { handleFocus() }}
//                                             onMouseOut={handleBlur}
//                                             defaultPosition={{ x: -76, y: -44 }}


//                                         >
//                                             <div className=' absolute z-40 text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>
//                                     </div>
//                                 )}
//                                 {selectedShape === 'oval' && selectedImage && (
//                                     <div className=' relative'>
//                                         {/* <img className='z-0' src={selectedImage}></img> */}

//                                         {/* <img className='z-0' src={selectedImage}></img> */}
//                                         <div>
//                                         <img onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                      className=' h-20 w-20 z-20'  src={selectedImage}
//                                             style={{
//                                                 zIndex: "1",
//                                                 width: '100%', // Adjust width as needed
//                                                 height: 'auto'
//                                                 , position: "relative",
//                                                 // Maintain aspect ratio
//                                             }}></img>
//                                             </div>
//                                         {isDraggable ? (
//                                             <Draggable
//                                                 scale={scale}
//                                                 position={position}
//                                                 onDrag={handleDrag}
//                                             >

//                                                 <div
//                                                     onMouseOver={() => { handleFocus() }}
//                                                     onMouseOut={handleBlur}
//                                                     className=' absolute z-40 p-10 cursor-move top-0 h-[600px] w-[800px] border-8 ' src={selectedImage2}
//                                                     style={{ transform: `scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                 </div>
//                                             </Draggable>
//                                         )
//                                             : (
//                                                 <Draggable scale={scale} position={position} onDrag={handleDrag}>

//                                                     <div

//                                                         className=' absolute z-0 p-10 rounded-full cursor-move top-0 h-[600px] w-[800px]  ' src={selectedImage2}
//                                                         style={{ translate: `(${position.x}px, ${position.y}px) scale(${scale})`, backgroundImage: `url(${selectedImage2})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}  >

//                                                     </div>
//                                                 </Draggable>

//                                             )
//                                         }

//                                         <Draggable
//                                             onMouseOver={() => { handleFocus() }}
//                                             onMouseOut={handleBlur}
//                                             defaultPosition={{ x: -76, y: -44 }}


//                                         >
//                                             <div className=' absolute z-40 text-4xl cursor-move hover:border top-[50%] left-[50%]' style={{ color: `${color}`, fontFamily: `${framTextfontfamilystate}` }}>{framtext}</div>
//                                         </Draggable>

//                                     </div>
//                                 )}
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//                 <div className="options12">


//                     <div className="upload-button my-4">
//                         <div>



//                             <button className="  rounded-md text-black relative" onClick={() => document.getElementById('my_modal_3').showModal()}>

//                                 🔍 Zoom

//                             </button>                     
//                             <dialog id="my_modal_3" className="modal">
//                                 <div className="modal-box">
//                                     <form method="dialog">
//                                         {/* if there is a button in form, it will close the modal */}
//                                         <button style={{ background: "transparent", color: "black" }} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-0 pb-20 m-20 pl-20">✕</button>
//                                     </form>
//                                     <div className=' flex items-center gap-7'>
//                                         -
//                                         <input
//                                             type="range"
//                                             min="20"
//                                             max="100"
//                                             className="range range-primary h-3"
//                                             value={scale * 100} // Convert scale factor back to percentage for the slider
//                                             onChange={handleSliderChange}
//                                         /> +
//                                     </div>
//                                 </div>
//                                 <form method="dialog" className="modal-backdrop">
//                                     <button style={{ color: "transparent", background: "transparent" }}>close</button>
//                                 </form>
//                             </dialog>


//                         </div>
//                         <button className="option rounded-md" onClick={() => document.getElementById('fileInput').click()}>
//                             🖼️   Select Photo
//                         </button>


//                         {/* You can open the modal using document.getElementById('ID').showModal() method */}
//                         <button className="text rounded-md" onClick={() => document.getElementById('my_modal_4').showModal()}>
//                             📝 Text
//                         </button>

//                         <dialog id="my_modal_4" className="modal">
//                             <div className="modal-box">
//                                 <form method="dialog">
//                                     {/* if there is a button in form, it will close the modal */}
//                                     <button style={{ background: "transparent", color: "black" }} className="btn  btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
//                                 </form>

//                                 <div className='w-full'>
//                                     <label className=' text-nowrap w-full inline-block text-start'>Add Text</label><br></br>
//                                     <input
//                                         type='text'
//                                         className='border w-72 md:w-96 relative left-0 md:mr-20 px-3 my-4 py-2 rounded-md'
//                                         placeholder='Enter Your Text here'
//                                         value={framtext} // Bind the state to the input value
//                                         onChange={(e) => setFramText(e.target.value)} // Update state on change
//                                     />
//                                     <label className=' text-nowrap  w-full inline-block text-start'>Text Color</label><br></br>
//                                     <div className=' flex'>
//                                         <input

//                                             type='color'
//                                             className='border w-20  relative left-0 inline-block h-8  md:mr-96  px-3 my-4  rounded-md'
//                                             placeholder='Enter Your Text here'
//                                             value={color} // Bind the state to the input value
//                                             onChange={(e) => setColor(e.target.value)} // Update state on change
//                                         />
//                                     </div>


//                                     <div className={`${framtext == "" ? "hidden" : "block"}`}>
//                                         <label className=' text-nowrap w-full inline-block text-start'>Text Style</label><br></br>
//                                         {
//                                             framTextfontfamily.map((text, index) => (
//                                                 <form method="dialog">
//                                                     <p className='border rounded-md my-3 text-2xl py-4 cursor-pointer' onClick={() => { setframTextfontfamilystate(text) }} key={index} style={{ fontFamily: `${text}`, color: `${color}`, }}>{framtext}</p>
//                                                 </form>
//                                             ))
//                                         }


//                                     </div>
//                                     <div>

//                                     </div>
//                                 </div>
//                                 <form method="dialog" className={`${framtext == "" ? "hidden" : "block"}`}>
//                                     {/* if there is a button in form, it will close the modal */}
//                                     <button style={{ background: "", color: "" }} className="btn text-white   w-full h-full  btn-outline btn-primar ">Save</button>
//                                 </form>
//                             </div>
//                         </dialog>
//                         <input
//                             type="file"
//                             id="fileInput"
//                             style={{ display: 'none' }}
//                             accept="image/*"
//                             onChange={handleImageUpload}
//                         />
//                     </div>

//                     <div className="shapes">
//                         <h3 className='acrylic'>Acrylic Photo Shapes</h3>
//                         <div className="shape-options pl-10  mt-2">

//                             <svg
//                                 viewBox="0 0 600 400"
//                                 className={` shape-svg1 ${selectedShape === 'rectangle' ? 'border-blue' : ''} `}
//                                 onClick={() => handleShapeClick('rectangle')}
//                             >
//                                 <rect x="10" y="10" width="480" height="280" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>
//                             <svg
//                                 viewBox="0 0 400 400"
//                                 className={`shape-svg2 ${selectedShape === 'square' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('square')}
//                             >
//                                 <rect x="10" y="10" width="280" height="280" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>


//                             <svg
//                                 viewBox="0 0 200 200"
//                                 className={`shape-svg3 ${selectedShape === 'circle' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('circle')}
//                                 width="100"
//                             >
//                                 <circle cx="100" cy="100" r="80" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>


//                             <svg
//                                 viewBox="0 0 400 400"
//                                 className={`shape-svg4 ${selectedShape === 'oval' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('oval')}
//                             >
//                                 <ellipse cx="200" cy="200" rx="200" ry="100" fill="#c1995d" stroke="" strokeWidth="5" />
//                             </svg>
//                             <svg
//                                 viewBox="0 0 400 400"
//                                 className={`shape-svg5 ${selectedShape === 'roundedSquare' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('roundedSquare')}
//                             >
//                                 <rect
//                                     x="50"
//                                     y="50"
//                                     width="300"
//                                     height="300"
//                                     rx="50"
//                                     ry="50"
//                                     fill="#c1995d"
//                                     stroke=""
//                                     strokeWidth="5"
//                                 />
//                             </svg>
//                             <svg
//                                 viewBox="0 0 600 400"
//                                 className={`shape-svg6 ${selectedShape === 'roundedRectangle' ? 'border-blue' : ''}`}
//                                 onClick={() => handleShapeClick('roundedRectangle')}
//                             >
//                                 <rect
//                                     x="50"
//                                     y="50"
//                                     width="500"
//                                     height="300"
//                                     rx="50"
//                                     ry="50"
//                                     fill="#c1995d"
//                                     stroke=""
//                                     strokeWidth="5"
//                                 />
//                             </svg>

//                         </div>

//                     </div>
//                     <div className="size-options">
//                         <br />
//                         <br />
//                         <div className='size'>
//                             <h3 className='acrylic1 ml-10 md:ml-0 my-3'>Size (Inch): {selectedSize}</h3>
//                             <div className='size-buttons'> {acrylicsize4.length > 0 ? (acrylicsize4.map((item, index) => {
//                                 return (
//                                     <div key={index}>
//                                         <button onClick={() => handleSizeChange1(item.size, item._id, item.price)} className={`size-button ${selectedSize === item.size ? 'active' : ''} rounded-md  ${selectedSize === item.size ? '' : 'hover:ring'}`}>{item.size}</button>

//                                     </div>


//                                 );

//                             })) : (
//                                 <div className=' flex gap-3 thickness-buttons'>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                         </div>
//                             )
//                             }
//                             </div>
//                             {/* <div className="size-buttons">
//                                 <button onClick={() => handleSizeChange1('11x11')} className={`size-button ${selectedSize === '11x11' ? 'active' : ''} rounded-md  ${selectedSize === '11x11' ? '' : 'hover:ring'}`}>11x11</button>
//                                 <button onClick={() => handleSizeChange1('16x12')} className={`size-button ${selectedSize === '16x12' ? 'active' : ''} rounded-md  ${selectedSize === '16x12' ? '' : 'hover:ring'}`}>16x12</button>
//                                 <button onClick={() => handleSizeChange1('16x16')} className={`size-button ${selectedSize === '16x16' ? 'active' : ''} rounded-md  ${selectedSize === '16x16' ? '' : 'hover:ring'}`}>16x16</button>
//                                 <button onClick={() => handleSizeChange1('21x15')} className={`size-button ${selectedSize === '21x15' ? 'active' : ''} rounded-md  ${selectedSize === '21x15' ? '' : 'hover:ring'}`}>21x15</button>
//                                 <button onClick={() => handleSizeChange1('35x23')} className={`size-button ${selectedSize === '35x23' ? 'active' : ''} rounded-md  ${selectedSize === '35x23' ? '' : 'hover:ring'}`}>35x23</button>
//                             </div> */}
//                         </div>

//                     </div>

//                     <div className="thickness-options mr-12  md:mr-48">
//                         <h3 className='acrylic1  my-3 '>Thickness: {selectedThickness}</h3>

//                         <div className='thickness-buttons'> {thicknessdata.length > 0 ? (thicknessdata.map((item, index) => {
//                             return (
//                                 <div key={index}>
//                                     {/* <button onClick={() => handleSizeChange1(item.size,item._id)} className={`size-button ${selectedSize === item.size ? 'active' : ''} rounded-md  ${selectedSize === item.size ? '' : 'hover:ring'}`}>{item.size}</button> */}
//                                     <button onClick={() => { handleThicknessChange(item.thickness, item.price, item._id) }} className={`thickness-button rounded-md ${selectedThickness === item.thickness ? 'active' : ''} ${selectedThickness === item.thickness ? '' : 'hover:ring'}`}>{item.thickness}</button>

//                                 </div>


//                             );

//                         })) : (<div className=' flex gap-3 thickness-buttons'>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                             <button className='btn skeleton p-6'></button>
//                         </div>)
//                         }
//                         </div>
//                         {/* <div className="thickness-buttons">
//                             <button onClick={() => handleThicknessChange('3MM')} className={`thickness-button rounded-md ${selectedThickness === '3MM' ? 'active' : ''} ${selectedThickness === '3MM' ? '' : 'hover:ring'}`}>3MM</button>
//                             <button onClick={() => handleThicknessChange('5MM')} className={`thickness-button rounded-md ${selectedThickness === '5MM' ? 'active' : ''} ${selectedThickness === '5MM' ? '' : 'hover:ring'}`}>5MM</button>
//                             <button onClick={() => handleThicknessChange('8MM')} className={`thickness-button rounded-md ${selectedThickness === '8MM' ? 'active' : ''} ${selectedThickness === '5MM' ? '' : 'hover:ring'}`}>8MM</button>
//                         </div> */}
//                     </div>
//                     <hr className='border-dashed border-[1px] border-gray-200 mb-2 w-screen md:w-[1200px]' />

//                     <div className='sticky top-0'>
//                         <div className="price1 text-sm">
//                             <span className='text-xl'>₹{sizeprice + thicknessprice} <span className=' line-through text-gray-300'>₹{sizeprice+thicknessprice + 1000}</span></span>
//                             <p className='mt-6' style={{ fontWeight: "300" }}>  Photo quality for <span style={{ fontWeight: "600" }}>{selectedSize}</span> is <span className='text-green-500'>Good</span></p>
//                             <p><span style={{ fontWeight: "300" }}>Quick mount:</span> <span className='text-bold text-balance'>Hridayam® Adhesive hooks (Included)</span></p>
//                         </div>

//                         {/* <Link to='/checkout'> */}
//                         <button onClick={captureScreenshot} className="buy-now rounded-md px-20">BUY IT NOW</button>
//                         {/* </Link> */}

//                         <div style={{ maxWidth: '60%', margin: '-30px auto' }}>

//                         </div>
//                     </div>
//                 </div>
//             </div>



//             <div>
//             </div>
//             <Footer />

//         </>
//     );
// }

// export default App;



import React, { useState } from "react";
import Modal from "react-modal";
import DefaultPreviewImage from "./images/preview (2).png";
import * as bodyPix from "@tensorflow-models/body-pix";
import "@tensorflow/tfjs";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useContext } from "react";
import './Catalog.css'; // Import your CSS file
// Import background images
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas-pro';
import Background1 from "./images/Designer2.png";
import Background2 from "./images/Designer1.png";
import Background3 from "./images/Designer1.png";
import { CartContext } from './CartContext';
import Preview from "./images/preview (2).png"
// import { textAlign } from "html2canvas-pro/dist/types/css/property-descriptors/text-align";

const BackgroundRemover = () => {
  const ref = useRef(null)
  const [uploadedImage, setUploadedImage] = useState(DefaultPreviewImage);
  const [processedImage, setProcessedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [framText, setFramText] = useState("");
  const [color, setColor] = useState("#000000");
  const [textStyle, setTextStyle] = useState("Arial");
  const [textPosition, setTextPosition] = useState({ top: 0, left: 0 });

  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedShape, setSelectedShape] = useState("rectangle");
  const [selectedSize, setSelectedSize] = useState('12x9');
  const [selectedThickness, setSelectedThickness] = useState('3MM');
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [text, setText] = useState('');
  const [scale, setScale] = useState(0.8);
  const [screenshot, setScreenshot] = useState(null);
  const [acrylicsize4, setacrylicsize4] = useState([]);
  const [thicknessdata, setthicknessdata] = useState([]);
  const [sizeId, setsizeId] = useState([]);
  const [thicknessprice, setthicknessprice] = useState();
  const [sizeprice, setsizeprice] = useState();
  const [thicknessId, setthicknessId] = useState();
  const [Acrylicsize, setAcrylicsize] = useState(null);
  const [isDraggable, setIsDraggable] = useState(false);
  const { addToCart } = useContext(CartContext)
  const navigate = useNavigate();
  const handleSliderChange = (e) => {
    setScale(e.target.value / 100);
    console.log(scale)
    // Convert range value to scale factor (0.2 to 1.0)
  };
  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  // function base64ToFile(base64String, filename) {
  //   // Decode the base64 string
  //   let arr = base64String.split(','),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]), // atob() decodes a base64 string
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   // Create a File object
  //   return new File([u8arr], filename, { type: mime });
  // }



  const captureScreenshot = () => {
    html2canvas(ref.current, {

      useCORS: true,
      scrollX: 0,
      scrollY: 1200,
      width: ref.current.scrollWidth, // Capture full width of the element
      height: ref.current.scrollHeight, // Capture full height of the element
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      let imageFile = base64ToFile(imgData, "image.jpg");
      // Store imgData in your store or state
      console.log(imageFile);
      setScreenshot(imageFile); // This is the base64 image
      // You can save it to a store (Redux, localStorage, etc.)
    })
  };

  const handleFocus = () => {
    setIsDraggable(true);
  };



  function base64ToFile(base64String, filename) {
    // Decode the base64 string
    let arr = base64String.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), // atob() decodes a base64 string
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Create a File object
    return new File([u8arr], filename, { type: mime });
  }


  const handleCapture = (captureFeedback) => {
    // Handle the captured feedback data
    console.log(captureFeedback + "sdfsdfjlkd");
  };

  const handlescreenshot = () => {
    const element = document.getElementById("divtotakescreenshotof")
    if (!element) {
      return;
    }
    html2canvas(element).then((canvas) => {
      let image = canvas.toDataURL("image/jpeg")
      let imageFile = base64ToFile(image, "image.jpg");
      setScreenshot(imageFile)
      console.log(screenshot)
    }).catch(err => {
      console.error("We can not take the screenshot of your element at this time")
    })
  }

  useEffect(() => {

    const fetchData = async () => {
      const fetchTokenFromLS = () => {
        return localStorage.getItem('token');
      };

      const fetchOrderId = () => {
        return localStorage.getItem('orderid');
      };

      const orderid = fetchOrderId();
      const token = fetchTokenFromLS();

      if (token) {
        try {
          const response = await axios.get('https://api.hirdayam.com/api/getAcrylicSize', {
            headers: {
              Authorization: `Bearer ${token}`, // Fixed interpolation
            },
          });

          if (response && response.data && response.data.data) {
            console.log('Get Acrylic size:', response);
            // let data=response;
            // setAcrylicsize(data); // Update state
            setsizeId(response.data.data[0]._id)
            setsizeprice(response.data.data[0].price)


            console.log(Acrylicsize + 'new');



          }

          if (response.data.status === true && Array.isArray(response.data.data)) {
            // Your logic here
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Acrylic size item error:', error);
        }
      } else {
        console.error('Token not found in localStorage.');
      }
    };

    fetchData();
  }, []);



  const handleBlur = () => {
    setIsDraggable(false);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);

  };

  const handleThicknessChange = (thickness, price, id) => {
    setSelectedThickness(thickness);
    setthicknessprice(price)
    setthicknessId(id)
    // setPrice(sizeprice+thicknessprice)
  };
  const handleImageClick1 = (imageSrc) => {
    setSelectedImage2(imageSrc);
  };
  const [price, setPrice] = useState(0);

  const sizePriceMap = {
    '12x9': 699,
    '11x11': 799,
    '16x12': 899,
    '16x16': 999,
    '21x15': 1199,
    '35x23': 1499,
  };

  const handleSizeChange1 = (size, sizeid, price) => {
    setSelectedSize(size);
    setsizeprice(price)
    setsizeId(sizeid)
    setPrice(sizeprice + thicknessprice);
  };

  const framTextfontfamily = ["Permanent Marker", "Grey Qo", "Matemasie", "Edu VIC WA NT Beginner", "Bodoni Moda SC"];




  useEffect(() => {
    fetchsize();
    // fetchthikness();
  }, []);

  // Empty dependency array to run only once on mount

  useEffect(() => {


    fetchthikness();


  }, [sizeId]);


  const fetchthikness = async () => {



    try {
      const response2 = await axios.get(`https://api.hirdayam.com/api/getAcrylicThickness?size_id=${sizeId}`, {
        headers: {
          // Authorization: `Bearer ${token}`, // Fixed interpolation
        },
      });

      if (response2 && response2.data && response2.data.data) {
        console.log('Get Acrylic Thikness:', response2);
        let acrylicThiknessdata = response2.data.data;
        setthicknessdata(acrylicThiknessdata); 


        
        setthicknessprice(response2.data.data[0].price)

        setSelectedThickness(response2.data.data[0].thickness) // Update state
        //     if(acrylicsize){
        console.log(response2.data.data[0].thickness + "thickness")
        setPrice(sizeprice + thicknessprice);
        console.log("updated", thicknessdata);

        // }

        // Ensure state is updated before logging
      }

      if (response2.data.status === true && Array.isArray(response2.data.data)) {
        // Your logic here
      } else {
        console.error('Unexpected response format:', response2.data);
      }
    } catch (error) {
      console.error('Acrylic thikeness item error:', error);
    }

  };



  const fetchsize = async () => {
    const fetchTokenFromLS = () => {
      return localStorage.getItem('token');
    };




    try {
      const response = await axios.get('https://api.hirdayam.com/api/getAcrylicSize', {
        headers: {
          // Authorization: `Bearer ${token}`, // Fixed interpolation
        },
      });

      if (response && response.data && response.data.data) {
        console.log('Get Acrylic size:', response);
        let acrylicSizedata = response.data.data;
        setacrylicsize4(acrylicSizedata); // Update state
        // if(acrylicsize){
        setSelectedSize(response.data.data[0].size);
        setsizeId(response.data.data[0]._id)
        console.log("updated", acrylicsize4);
        setsizeprice(response.data.data[0].price)

        // }

        // Ensure state is updated before logging
      }

      if (response.data.status === true && Array.isArray(response.data.data)) {
        // Your logic here
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Acrylic size item error:', error);
    }
    // } else {
    //     console.error('Token not found in localStorage.');
    // }
  };




  const handleAddToCart = () => {
    // console.log(getSingleProduct.variations[0]+"new variation")
    console.log(sizeId)
    if (true) {
      const productToAdd = {
        // id: "",
        // name: "",
        // price:"",
        // image: "",
        // text:"",
        // color:"" ,
        // variation: "",



        acrylicsizeid: sizeId,
        acrylicsizename: selectedSize,
        acrylicthicknessid: thicknessId,
        acrylicthicknessname: selectedThickness,
        acrylicprice: sizeprice + thicknessprice,
        acrylicfinalpreview: screenshot,

      };
      console.log(productToAdd.acrylicsizename + "new Acylic")

      //   if(file==null && filteredCard?.product_type=="personalize"){
      //       alert("Please Select a Image")
      //   }
      //   else{

      if (screenshot != null) {


        addToCart(productToAdd, 1);
        navigate('/cart');
      }
      //   }

    }
  };


  useEffect(() => {
    handleAddToCart();
  }, [screenshot])

  if (screenshot != null) {
    handleAddToCart();
  }



  // Handle image upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
        setProcessedImage(null); // Reset processed image
      };
      reader.readAsDataURL(file);
    }
  };

  // Apply the selected background
  const handleApplyBackground = async () => {
    if (!uploadedImage || !selectedBackground) {
      alert("Please upload an image and select a background first.");
      return;
    }

    setIsProcessing(true);

    try {
      const net = await bodyPix.load({
        architecture: "MobileNetV1",
        outputStride: 16,
        multiplier: 0.75,
        quantBytes: 2,
      });

      const img = new Image();
      img.src = uploadedImage;

      img.onload = async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);

        const segmentation = await net.segmentPerson(img);
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        const { data } = imageData;

        for (let i = 0; i < data.length; i += 4) {
          const isPerson = segmentation.data[i / 4];
          if (!isPerson) {
            data[i] = 0; // Red
            data[i + 1] = 0; // Green
            data[i + 2] = 0; // Blue
            data[i + 3] = 0; // Alpha (fully transparent)
          }
        }

        ctx.putImageData(imageData, 0, 0);

        const backgroundImg = new Image();
        backgroundImg.src = selectedBackground;

        backgroundImg.onload = () => {
          ctx.globalCompositeOperation = "destination-over";
          ctx.drawImage(backgroundImg, 0, 0, canvas.width, canvas.height);

          // Set processed image as the final result
          const resultUrl = canvas.toDataURL();
          setProcessedImage(resultUrl);
          setIsModalOpen(false);
          setIsProcessing(false);
        };
      };
    } catch (error) {
      console.error("Error applying background:", error);
      setIsProcessing(false);
      alert("An error occurred while applying the background.");
    }
  };

  // Save edited text to state and re-render
  // const handleSaveText = () => {
  //   setIsTextModalOpen(false);
  //   setTextPosition({ top: "50", left: "40" }); // Reset text position to center after saving
  // };
  // const handleSaveText = () => {
  //   const parentElement = ref.current; // The parent container (image container)
  //   const textElement = document.querySelector('.draggable-text'); // Text element
  
  //   if (parentElement && textElement) {
  //     const parentRect = parentElement.getBoundingClientRect();
  //     const textRect = textElement.getBoundingClientRect();
  
  //     // Calculate the center position
  //     const centerX = parentRect.width / 2 - textRect.width / 2;
  //     const centerY = parentRect.height / 2 - textRect.height / 2;
  
  //     // Update the text position state
  //     setTextPosition({ left: centerX, top: centerY });
  //   }
  // };
  
  const handleSaveText = () => {
    const imageElement = document.querySelector(".processed-image"); // Reference the image
    const textElement = document.querySelector(".draggable-text"); // Reference the text element
  
    if (imageElement && textElement) {
      const imageRect = imageElement.getBoundingClientRect();
      const textRect = textElement.getBoundingClientRect();
  
      // Calculate center position relative to the image
      const centerX = imageRect.width / 2 - textRect.width / 2;
      const centerY = imageRect.height / 2 - textRect.height / 2;
  
      // Update the text position state to center it
      setTextPosition({
        left: centerX,
        top: centerY,
      });
    }
  };
  
  // Handle the "Add/Edit Text" button click
  const handleAddTextClick = () => {
    if (uploadedImage === DefaultPreviewImage) {
      alert("Please select a photo first.");
    } else {
      setIsTextModalOpen(true); // Open the text editing modal if a photo is selected
    }
  };
  const handleAddBackground = () => {
    if (uploadedImage === DefaultPreviewImage) {
      alert("Please select a photo first.");
    } else {
      // setIsTextModalOpen(true); // Open the text editing modal if a photo is selected
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Header />
      <div className="text-center mt-6">


        <div className="flex justify-center items-center"> {/* Parent container to center everything */}
          
          <div ref={ref}
            className="relative flex justify-center items-center h-96 border mb-4"
            style={{
              backgroundImage: `url(${Background1 || ''})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50rem",
              position: "relative", // Ensure relative positioning
              overflow: "hidden", // Prevent dragging outside bounds
            }}
          >
            {/* Image or processed image */}
            <img
              src={processedImage || uploadedImage}
              alt="Preview"
              className="processed-image max-w-full max-h-full object-cover"
            />

            {framText && (
              <Draggable
                bounds="parent"
                defaultPosition={{ x: textPosition.left, y: textPosition.top }}
                onStop={(e, data) => setTextPosition({ left: data.x, top: data.y })}
              >
                <div
                 className="draggable-text"
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    fontFamily: textStyle,
                    fontSize: "30px",
                    color: color,
                    zIndex: 10,
                    cursor: "move",
                    pointerEvents: "auto",
                  }}
                >
                  {framText}
                </div>
              </Draggable>
            )}

          </div>
        </div>


        <div className="flex justify-center items-center space-x-4 mb-4">
          {/* Select Photo Button */}
          <label
            htmlFor="file-upload"
            className="bg-[#23387A] text-white py-2 px-4 rounded cursor-pointer"

          >
            🖼️   Select Photo
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            className="hidden"
          />

          {/* Select Background Button */}
          {/* <button
            onClick={() => setIsModalOpen(true)}
            className="bg-[#23387A]  text-white py-2 px-4 rounded  transition"
          >
            Select Background
          </button> */}
           <button
    // onClick={() => {
    //   if (!processedImage && !uploadedImage) {
    //     // Show alert if no image is uploaded
    //     alert("Please upload a photo first.");
    //   } else {
    //     // Open the modal if an image is uploaded
    //     setIsModalOpen(true);
    //   }
    // }}
    onClick={handleAddBackground} 
    className="bg-[#23387A] text-white py-2 px-4 rounded transition"
  >
    Select Background
  </button>

          {/* Add Text Button */}
          <button
            onClick={handleAddTextClick} // Use the new handler
            className="bg-[#23387A] text-white py-2 px-4 rounded transition"
          >
            📝 Text
          </button>
        </div>

        {/* Modal for background selection */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Select Background"
          className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-10"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
          <h2 className="text-center text-xl font-bold mb-6">Available Backgrounds</h2>
          <div className="grid grid-cols-3 gap-4">
            {[Background1, Background2, Background3].map((bg, index) => (
              <img
                key={index}
                src={bg}
                alt={`Background ${index + 1}`}
                onClick={() => setSelectedBackground(bg)}
                className={`cursor-pointer border ${bg === selectedBackground ? "border-blue-500" : "border-gray-300"
                  }`}
              />
            ))}
          </div>
          <button
            onClick={handleApplyBackground}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition mt-6 block mx-auto"
          >
            Apply Background
          </button>
        </Modal>

        {/* Modal for adding/editing text */}
        <dialog id="my_modal_4" className="modal" open={isTextModalOpen}>
          <div className="modal-box">
            <form method="dialog">
              <button
                style={{ background: "transparent", color: "black" }}
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              >
                ✕
              </button>
            </form>

            <div className="w-full">
              <label className="text-nowrap w-full inline-block text-start">
                Add Text
              </label>
              <br />
              <input
                type="text"
                className="border w-72 md:w-96 relative left-0 md:mr-20 px-3 my-4 py-2 rounded-md"
                placeholder="Enter Your Text here"
                value={framText}
                onChange={(e) => setFramText(e.target.value)} // Update state on change
              />
              <label className="text-nowrap w-full inline-block text-start">
                Text Color
              </label>
              <br />
              <div className="flex">
                <input
                  type="color"
                  className="border w-20 relative left-0 inline-block h-8 md:mr-96 px-3 my-4 rounded-md"
                  value={color}
                  onChange={(e) => setColor(e.target.value)} // Update color on change
                />
              </div>


              <div className={`${framText === "" ? "hidden" : "block"}`}>
                <label className="text-nowrap w-full inline-block text-start">Text Style</label>
                <br />
                <form method="dialog">
                  <p
                    className="border rounded-md my-3 text-2xl py-4 cursor-pointer"
                    onClick={() => setTextStyle("Arial")}
                    style={{ fontFamily: "Arial", color: color }}
                  >
                    {framText}
                  </p>
                  <p
                    className="border rounded-md my-3 text-2xl py-4 cursor-pointer"
                    onClick={() => setTextStyle("Courier New")}
                    style={{ fontFamily: "Courier New", color: color }}
                  >
                    {framText}
                  </p>
                </form>
              </div>

              <form method="dialog" className={`${framText === "" ? "hidden" : "block"}`}>
                <button
                  onClick={handleSaveText}
                  className="btn text-white w-full h-full btn-outline btn-primary"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </dialog>
        <div className="size-options">
          <br />
          <br />
          <div className='size'>
            <h3 style={{ fontFamily: "Roboto", fontSize: "14px", fontWeight: "500" }}>Size (Inch): {selectedSize}</h3>
            <div className='size-buttons'> {acrylicsize4.length > 0 ? (acrylicsize4.map((item, index) => {
              return (
                <div key={index}>
                  <button onClick={() => handleSizeChange1(item.size, item._id, item.price)} className={`size-button ${selectedSize === item.size ? 'active' : ''} rounded-md  ${selectedSize === item.size ? '' : 'hover:ring'}`}>{item.size}</button>

                </div>


              );

            })) : (
              <div className=' flex gap-3 thickness-buttons'>
                <button className='btn skeleton p-6'></button>
                <button className='btn skeleton p-6'></button>
                <button className='btn skeleton p-6'></button>
                <button className='btn skeleton p-6'></button>
                <button className='btn skeleton p-6'></button>
              </div>
            )
            }
            </div>
            {/* <div className="size-buttons">
                                <button onClick={() => handleSizeChange1('11x11')} className={`size-button ${selectedSize === '11x11' ? 'active' : ''} rounded-md  ${selectedSize === '11x11' ? '' : 'hover:ring'}`}>11x11</button>
                                <button onClick={() => handleSizeChange1('16x12')} className={`size-button ${selectedSize === '16x12' ? 'active' : ''} rounded-md  ${selectedSize === '16x12' ? '' : 'hover:ring'}`}>16x12</button>
                                <button onClick={() => handleSizeChange1('16x16')} className={`size-button ${selectedSize === '16x16' ? 'active' : ''} rounded-md  ${selectedSize === '16x16' ? '' : 'hover:ring'}`}>16x16</button>
                                <button onClick={() => handleSizeChange1('21x15')} className={`size-button ${selectedSize === '21x15' ? 'active' : ''} rounded-md  ${selectedSize === '21x15' ? '' : 'hover:ring'}`}>21x15</button>
                                <button onClick={() => handleSizeChange1('35x23')} className={`size-button ${selectedSize === '35x23' ? 'active' : ''} rounded-md  ${selectedSize === '35x23' ? '' : 'hover:ring'}`}>35x23</button>
                            </div> */}
          </div>

        </div>

        <div className="thickness-options mr-12  md:mr-48">
          <h3 style={{ fontFamily: "Roboto", fontSize: "14px", fontWeight: "500" }}>Thickness: {selectedThickness}</h3>

          <div className='thickness-buttons'> {thicknessdata.length > 0 ? (thicknessdata.map((item, index) => {
            return (
              <div key={index}>
                {/* <button onClick={() => handleSizeChange1(item.size,item._id)} className={`size-button ${selectedSize === item.size ? 'active' : ''} rounded-md  ${selectedSize === item.size ? '' : 'hover:ring'}`}>{item.size}</button> */}
                <button onClick={() => { handleThicknessChange(item.thickness, item.price, item._id) }} className={`thickness-button rounded-md ${selectedThickness === item.thickness ? 'active' : ''} ${selectedThickness === item.thickness ? '' : 'hover:ring'}`}>{item.thickness}</button>

              </div>


            );

          })) : (<div className=' flex gap-3 thickness-buttons'>
            <button className='btn skeleton p-6'></button>
            <button className='btn skeleton p-6'></button>
            <button className='btn skeleton p-6'></button>
            <button className='btn skeleton p-6'></button>
            <button className='btn skeleton p-6'></button>
          </div>)
          }
          </div>
          {/* <div className="thickness-buttons">
                            <button onClick={() => handleThicknessChange('3MM')} className={`thickness-button rounded-md ${selectedThickness === '3MM' ? 'active' : ''} ${selectedThickness === '3MM' ? '' : 'hover:ring'}`}>3MM</button>
                            <button onClick={() => handleThicknessChange('5MM')} className={`thickness-button rounded-md ${selectedThickness === '5MM' ? 'active' : ''} ${selectedThickness === '5MM' ? '' : 'hover:ring'}`}>5MM</button>
                            <button onClick={() => handleThicknessChange('8MM')} className={`thickness-button rounded-md ${selectedThickness === '8MM' ? 'active' : ''} ${selectedThickness === '5MM' ? '' : 'hover:ring'}`}>8MM</button>
                        </div> */}
        </div>
        <hr className='border-dashed border-[1px] border-gray-200 mb-2 w-screen md:w-[1200px]' />

        <div className='sticky top-0'>
          <div className="price1 text-sm">
            <span className='text-xl'>₹{sizeprice + thicknessprice} <span className=' line-through text-gray-300'>₹{sizeprice + thicknessprice + 1000}</span></span>
            <p className='mt-6' style={{ fontWeight: "300" }}>  Photo quality for <span style={{ fontWeight: "600" }}>{selectedSize}</span> is <span className='text-green-500'>Good</span></p>
            <p><span style={{ fontWeight: "300" }}>Quick mount:</span> <span className='text-bold text-balance'>Hridayam® Adhesive hooks (Included)</span></p>
          </div>


          <button onClick={captureScreenshot} className="buy-now rounded-md px-20">BUY IT NOW</button>


          <div style={{ maxWidth: '60%', margin: '-30px auto' }}>

          </div>
        </div>
      </div>


      <Footer />
    </>
  );
};

export default BackgroundRemover;
