// import React, { useState, useEffect } from 'react'
// import Header from './Header'
// import './MyOrder.css'
// import axios from 'axios'
// import Ripples from 'react-ripples'

// const MyOrder = () => {

//   const [orderdetails, setorderdetails] = useState([]);
//   const [orderTotal, setordertotal] = useState("")

//   const [orderdate, setorderdate] = useState("");
//   const [orderstatus, setorderstatus] = useState("");
//   const [payment, setpayment] = useState("");
//   const [inputorderid, setinputorderid] = useState("");
//   const [inputorderid2, setinputorderid2] = useState("");
//   const [orderid, setorderid] = useState("");
//   const [loading, setloading] = useState(false);
//   const date = new Date(orderdate);

//   // Options for formatting

//   const handleinputbox = () => {
//     setinputorderid2(inputorderid);
//     fetchorderdetails();
//   }

//   const options = {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: true, // for AM/PM format
//     timeZoneName: 'short'
//   };

//   // Format the date
//   const formattedDate = date.toLocaleDateString('en-US', options);



//   useEffect(() => {

//     fetchorderdetails();
//     console.log(orderdetails)

//   }, [])


// const cancelOrder = () => {
//   setloading(true)
//   const fetchTokenFromLS = () => {
//     return localStorage.getItem('token');
//   };

//   const token = fetchTokenFromLS();
//   console.log(token)
//   console.log(orderid)

//   if (token && orderid) {
//     axios.post('https://api.hirdayam.com/api/cancelOrder',
//       {
//         order_id: orderid // Pass the orderid from the state
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         }
//       })
//       .then(response => {
//         window.location.reload();
//         console.log('API Response Data:', response);
//         setorderdetails(response)
//         setorderdate("df")
//         setordertotal(response.data.data.order_amount)
//         setorderstatus(response.data.data.order_status)
//         setpayment(response.data.data.payment_status);
//         setorderid(response.data.data.order_id)
//         console.log(response.data.data.order_status + "lkjsdlsdldsjf")
//         setorderdate(response.data.data.updated_at)

//         if (response.data.status === true) {
//           setorderdetails(response);
//           //setOrderDetails(response.data.data);  // Handle the order details
//         } else {
//           console.error('Unexpected response format:', response.data);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching order item:', error);
//       });
//   } else {
//     console.error('Token not found in localStorage or order ID is missing.');
//   }
// };


//   const fetchorderdetails = () => {
//     const fetchTokenFromLS = () => {
//       return localStorage.getItem('token');
//     };

//     const fetchorderid = () => {
//       return localStorage.getItem('orderid');
//     };

//     const orderid = fetchorderid();
//     const token = fetchTokenFromLS();

//     if (token) {
//       axios.get(`https://api.hirdayam.com/api/getOrders`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         }
//       })
//         .then(response => {
//           console.log('API Response Data:', response);
//           setorderdetails(response)
//           setorderdate("df")
//           setordertotal(response.data.data.order_amount)
//           setorderstatus(response.data.data.order_status)
//           setpayment(response.data.data.payment_status);
//           setorderid(response.data.data.order_id)
//           console.log(response.data.data.order_status + "lkjsdlsdldsjf")
//           setorderdate(response.data.data.updated_at)
//           if (response.data.status === true && Array.isArray(response)) {
//             setorderdetails(response);
//           } else {
//             setorderdetails(response?.data?.data);
//             console.error('Unexpected response format:', response.data);
//           }
//         })
//         .catch(error => {
//           console.error('Error fetching order item:', error);
//         });
//     } else {
//       console.error('Order Id found in localStorage.');
//     }
//   };

//   return (
//     <>
//       <Header />
//       {/* 
//      <table className="order-table-container">
//       <thead className="table-header">
//         <th className="header-cell">Order</th>
//         <div className="header-cell">Order Date</div>
//         <div className="header-cell">Status</div>
//         <div className="header-cell">Total</div>
//         <div className="header-cell">Action</div>

//       </thead>

//       <tbody className="table-body">
//         <div className="no-order-found">No order found</div>
//         <div className="body-cell">Order</div>


//       </tbody>


//     </table> */}
//       <div className='mx-14 my-5 flex gap-3 '>
//         <input onChange={(e) => { setinputorderid(e.target.value) }} placeholder='Enter OrderId' className='border py-2 px-2 rounded-md' type='text'></input>
//         <button onClick={handleinputbox} className='btn bg-blue-900 text-white'>Search Order</button>
//       </div>
//       <table className="wishlist-table w-11/12 m-auto">
//         <thead>
//           <tr>
//             {/* <th>Select</th> */}
//             {/* <th>{orderdetails.data.message}</th> */}

//             <th>Order Date</th>
//             <th>Payment</th>
//             <th>Status</th>
//             <th>Total</th>

//             <th className={`${orderstatus == "cancelled" ? "hidden" : "block"}`}>Action</th>
//           </tr>
//         </thead>
//         <tbody>


//           <tr>
//             <td className=' font-semibold'>{formattedDate}</td>
//             <td>{payment}</td>

//             <td>{orderstatus}</td>
//             <td>{orderTotal}</td>
//             <td className={`${orderstatus == "cancelled" ? "hidden" : "block"}`}>
//               <Ripples onClick={() => document.getElementById('my_modal_3').showModal()}>
//                 <div className='text-white cursor-pointer gap-3 py-1 px-2 flex items-center justify-center rounded-md bg-blue-900'>
//                   <img width={"30px"} src='https://cdn-icons-png.flaticon.com/512/4379/4379685.png'>

//                   </img>
//                   order
//                 </div>
//               </Ripples>

//             </td>

//           </tr>

//         </tbody>
//       </table>

//       {/* You can open the modal using document.getElementById('ID').showModal() method */}
//       {/* <button className="btn" >open modal</button> */}
//           <dialog id="my_modal_3" className="modal">
//            <div className="modal-box flex items-center  justify-center">
//           {/* <form method="dialog">
//       if there is a button in form, it will close the modal
//       <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
//     </form> */}
//           <div>
//             <img className='m-auto' width={"70px"} src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/OOjs_UI_icon_notice-warning.svg/1200px-OOjs_UI_icon_notice-warning.svg.png'></img>
//             <p className=' font-semibold text-3xl text-center my-3'>

//               Are You Sure ?</p>
//             <p className='my-4 text-center'>You Want To Cancel This Order!</p>
//             <div className='flex justify-between w-full gap-7 my-6'>
//               <button onClick={() => { cancelOrder(orderid) }} className='bg-blue-900 flex items-center justify-center gap-3 cursor-pointer text-white p-2 rounded-md'>
//                 <span className={`loading  bg-white loading-spinner text-primary ${loading == false ? "hidden" : "block"}`}></span>
//                 Yes Cancel Order it!
//               </button>
//               <form method="dialog">
//                 <button className='bg-red-500 cursor-pointer text-white p-2 rounded-md'>Cancel</button>
//               </form>


//             </div>
//           </div>
//         </div>
//       </dialog>


//     </>
//   )
// }

// export default MyOrder

// import React, { useState, useEffect } from 'react';
// import Header from './Header';
// import './MyOrder.css';
// import axios from 'axios';
// import Ripples from 'react-ripples';

// const MyOrder = () => {
//   const [orderdetails, setorderdetails] = useState([]);
//   const [loading, setloading] = useState(false);
//   const [inputorderid, setinputorderid] = useState(''); // Add state for input

//   // Fetch order details once component mounts
//   useEffect(() => {
//     fetchOrderDetails();
//   }, []);

//   // Function to fetch order details
//   const fetchOrderDetails = () => {
//     const fetchTokenFromLS = () => localStorage.getItem('token');
//     const token = fetchTokenFromLS();

//     if (token) {
//       axios
//         .get('https://api.hirdayam.com/api/getOrders', {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           // Debugging response
//           setorderdetails(response?.data?.data?.data)
//           console.log('API Response:', response.data);

//           if (response.data.status === true && Array.isArray(response.data.data)) {
//             setorderdetails(response.data.data); // Update state with fetched order details
//           } else {
//             console.error('Unexpected response format:', response.data);
//           }
//         })
//         .catch((error) => {
//           console.error('Error fetching order details:', error);
//         });
//     } else {
//       console.error('Token not found in localStorage.');
//     }
//   };

//   // Function to handle order cancellation
//   const cancelOrder = (orderId) => {
//     setloading(true);
//     const fetchTokenFromLS = () => localStorage.getItem('token');
//     const token = fetchTokenFromLS();

//     if (token && orderId) {
//       axios
//         .post(
//           'https://api.hirdayam.com/api/cancelOrder',
//           { order_id: orderId },
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         )
//         .then((response) => {
//           if (response.data.status === true) {
//             fetchOrderDetails(); // Refresh order details after cancellation
//           }
//           setloading(false);
//         })
//         .catch((error) => {
//           console.error('Error canceling order:', error);
//           setloading(false);
//         });
//     }
//   };

//   // Handle search button click
//   const handleInputBox = () => {
//     if (inputorderid) {
//       const filteredOrders = orderdetails.filter((order) =>
//         order.cus_order_id && order.cus_order_id.includes(inputorderid)
//       );
//       setorderdetails(filteredOrders);
//     } else {
//       fetchOrderDetails(); // If input is empty, show all orders
//     }
//   };

//   // Log orderdetails to check state
//   useEffect(() => {
//     console.log('Current Orders in State:', orderdetails); // Check the order details in state
//   }, [orderdetails]);

//   return (
//     <>
//       <Header />
//       <div className="mx-14 my-5 flex gap-3">
//         <input
//           onChange={(e) => setinputorderid(e.target.value)}
//           placeholder="Enter OrderId"
//           className="border py-2 px-2 rounded-md"
//           type="text"
//         />
//         <button onClick={handleInputBox} className="btn bg-blue-900 text-white">
//           Search Order
//         </button>
//       </div>

//       {/* Order Table */}
//       <table className="wishlist-table w-11/12 m-auto">
//         <thead>
//           <tr>
//             <th>Order Date</th>
//             <th>Payment</th>
//             <th>Status</th>
//             <th>Total</th>
//             <th className="action-column">Action</th>
//           </tr>
//         </thead>
//         <tbody>

//           {

//           orderdetails?.length > 0 ? (
//             orderdetails.map((order) => {
//               const formattedDate = new Date(order.createdAt).toLocaleDateString('en-US', {
//                 year: 'numeric',
//                 month: 'long',
//                 day: 'numeric',
//                 hour: '2-digit',
//                 minute: '2-digit',
//                 second: '2-digit',
//                 hour12: true,
//                 timeZoneName: 'short',
//               });

//               return (
//                 <tr key={order._id}>
//                   <td className="font-semibold">{formattedDate}</td>
//                   <td>{order.payment_status}</td>
//                   <td>{order.order_status}</td>
//                   <td>{order.order_amount}</td>
//                   <td className={`${order.order_status === 'cancelled' ? 'hidden' : 'block'}`}>
//                     <Ripples onClick={() => document.getElementById('my_modal_3').showModal()}>
//                       <div className="text-white cursor-pointer gap-3 py-1 px-2 flex items-center justify-center rounded-md bg-blue-900">
//                         <img
//                           width={'30px'}
//                           src="https://cdn-icons-png.flaticon.com/512/4379/4379685.png"
//                           alt="Order Action"
//                         />
//                         Order
//                       </div>
//                     </Ripples>
//                   </td>
//                 </tr>
//               );
//             })
//           ) : (
//             <tr>
//               <td colSpan="5" className="text-center">
//                 No orders found
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </table>

//       {/* Modal for canceling order */}
//       <dialog id="my_modal_3" className="modal">
//         <div className="modal-box flex items-center justify-center">
//           <div>
//             <img
//               className="m-auto"
//               width="70px"
//               src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/OOjs_UI_icon_notice-warning.svg/1200px-OOjs_UI_icon_notice-warning.svg.png"
//               alt="Warning"
//             />
//             <p className="font-semibold text-3xl text-center my-3">Are You Sure?</p>
//             <p className="my-4 text-center">You Want To Cancel This Order!</p>
//             <div className="flex justify-between w-full gap-7 my-6">
//               <button
//                 onClick={() => cancelOrder(order._id)}
//                 className="bg-blue-900 flex items-center justify-center gap-3 cursor-pointer text-white p-2 rounded-md"
//               >
//                 <span
//                   className={`loading bg-white loading-spinner text-primary ${loading ? 'block' : 'hidden'}`}
//                 ></span>
//                 Yes, Cancel Order!
//               </button>
//               <form method="dialog">
//                 <button className="bg-red-500 cursor-pointer text-white p-2 rounded-md">Cancel</button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </dialog>
//     </>
//   );
// };

// export default MyOrder;
import React, { useState, useEffect } from 'react';
import Header from './Header';
import './MyOrder.css';
import axios from 'axios';
import Ripples from 'react-ripples';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MyOrder = () => {
  const [orderdetails, setorderdetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [orders, setOrders] = useState([]); // Initialize orders in state
  const [inputorderid, setinputorderid] = useState('');
  const [viewOrderDetails, setViewOrderDetails] = useState(null); // State to hold the view order details
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [orderstatus, setorderstatus] = useState("");
  const [orderid, setorderid] = useState("");
  const [orderdate, setorderdate] = useState("");

  // Fetch order details once component mounts
  useEffect(() => {
    fetchOrderDetails();
  }, []);
  // const handleCancelClick = (id) => {
  //   console.log('Initial order ID:', id);  // Check if ID is correct here
  //   if (id) {
  //     cancelOrder(id);
  //   } else {
  //     console.error('Order ID is missing or undefined when calling handleCancelClick');
  //   }
  // };
  const handleCancelClick = (id) => {
    console.log('Initial order ID:', id); // Check if ID is correct
    if (id) {
      // Simulate successful cancellation for now
      alert('Order canceled successfully');
      // Optionally, call cancelOrder function if you need to send the request
      cancelOrder(id);
    } else {
      console.error('Order ID is missing or undefined when calling handleCancelClick');
      alert('Error: Order ID is missing.');
    }
  };
  // Function to fetch order details
  const fetchOrderDetails = () => {
    const fetchTokenFromLS = () => localStorage.getItem('token');
    const token = fetchTokenFromLS();

    if (token) {
      axios
        .get('https://api.hirdayam.com/api/getOrders', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setorderdetails(response?.data?.data?.data);
          console.log('API Responsessssss:', response.data);
          setOrders(response.data.orders);
        })
        .catch((error) => {
          console.error('Error fetching order details:', error);
        });
    } else {
      console.error('Token not found in localStorage.');
    }
  };

  console.log('Initial order ID:', orderid);

  const cancelOrder = (orderId) => {
    console.log('Order ID to Cancel:', orderId);  // Confirm order ID before API call
    setloading(true);

    const token = localStorage.getItem('token');

    if (token && orderId) {
      axios
        .post(
          'https://api.hirdayam.com/api/cancelOrder',
          { order_id: orderId },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.data.status) {
            console.log('Order canceled successfully:', response.data);
            setorderdetails((prevOrders) =>
              prevOrders.map((order) =>
                order._id === orderId ? { ...order, order_status: 'cancelled' } : order
              )
            );
          } else {
            console.error('Failed to cancel the order:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error cancelling order:', error);
        })
        .finally(() => setloading(false));
    } else {
      console.error('Token or Order ID is missing.');
      setloading(false);
    }
  };

  // Handle search button click
  const handleInputBox = () => {
    if (inputorderid) {
      const filteredOrders = orderdetails.filter((order) =>
        order.cus_order_id && order.cus_order_id.includes(inputorderid)
      );
      setorderdetails(filteredOrders);
    } else {
      fetchOrderDetails(); // If input is empty, show all orders
    }
  };

  // Function to handle the "View" button click
  const handleViewClick = (cus_order_id) => {
    const token = localStorage.getItem('token'); // Fetch token from localStorage

    if (!token) {
      console.error('Token is required but not found.');
      return;
    }

    // Fetch details of the particular order with the token in the request header
    axios
      .get(`https://api.hirdayam.com/api/getOrderdetails?order_id=${cus_order_id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      })
      .then((response) => {
        if (response.data.status === true) {
          setViewOrderDetails(response.data.data); // Set the details to state
          setIsModalOpen(true); // Open the modal
        } else {
          console.error('Error: Order details not found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching order details:', error);
      });
  };

  // Log orderdetails to check state
  useEffect(() => {
    console.log('Current Orders in State:', orderdetails); // Check the order details in state
  }, [orderdetails]);

  return (
    <>
      <Header />
      {/* <div className="mx-14 my-5 flex gap-3">
        <input
          onChange={(e) => setinputorderid(e.target.value)}
          placeholder="Enter OrderId"
          className="border py-2 px-2 rounded-md"
          type="text"
        />
        <button onClick={handleInputBox} className="btn bg-blue-900 text-white">
          Search Order
        </button>
      </div> */}

      {/* Order Table */}
      <table className="wishlist-table w-11/12 m-auto mt-10">
  <thead>
    <tr>
      <th>Order Date</th>
      <th>Status</th>
      <th>Total</th>
      <th>Action</th> {/* Always show Action column */}
      <th>View</th>
    </tr>
  </thead>
  <tbody>
    {orderdetails?.length > 0 ? (
      orderdetails.map((order) => {
        const formattedDate = new Date(order.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
          timeZoneName: 'short',
        });

        return (
          <tr key={order._id}>
            <td className="font-semibold">{formattedDate}</td>
            <td>{order.order_status}</td>
            <td>{order.order_amount}</td>
            <td>
              {order.order_status === 'cancelled' ? (
                <span className="text-red-500 font-semibold">Cancelled</span>
              ) : (
                <Ripples onClick={() => handleCancelClick(order._id)}>
                  <div className="text-white cursor-pointer gap-3 py-2 px-2 flex items-center justify-center rounded-md bg-blue-900">
                    <img
                      width="30px"
                      src="https://cdn-icons-png.flaticon.com/512/4379/4379685.png"
                      alt="Order Action"
                    />
                    <span className="hidden sm:inline">Cancel Order</span>
                  </div>
                </Ripples>
              )}
            </td>
            <td>
              <button
                onClick={() => handleViewClick(order.cus_order_id)} // View button click
                className="btn bg-black-500 text-black"
              >
                View
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          No orders found
        </td>
      </tr>
    )}
  </tbody>
</table>



      {isModalOpen && viewOrderDetails && (
        <div className="fixed z-20 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div
              className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              style={{ maxWidth: '700px' }}
            >
              <div className="flex flex-col items-center justify-center">
                <h2
                  className="leading-6 font-medium text-gray-900 mb-6"
                  style={{ fontFamily: 'Poppins', fontWeight: 'bolder', fontSize: '18px' }}
                >
                  Order Details
                </h2>
                <button
                  type="button"
                  className="absolute right-[-5rem] top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={() => setIsModalOpen(false)}
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </button>
                <div className="w-full text-left">
                  <p><strong>Order ID:</strong> {viewOrderDetails.cus_order_id}</p>
                  <p><strong>Customer Name:</strong> {viewOrderDetails.user.name}</p>
                  <p><strong>Email:</strong> {viewOrderDetails.user.email}</p>
                  <p><strong>Phone:</strong> {viewOrderDetails.user.phone}</p>
                  <p><strong>Address:</strong> {viewOrderDetails.delivery_address.house_name}, {viewOrderDetails.delivery_address.area_name}</p>
                  <p><strong>Amount:</strong> ₹{viewOrderDetails.order_amount}</p>

                  {viewOrderDetails.items.map((item, index) => (
                    <div key={index} className="my-4 border-t pt-4">
                      {item.acrylic_final_preview ? (
                        // Acrylic Order Details
                        <div>
                          <h3 className="font-semibold text-lg">Acrylic Order Details</h3>
                          <p><strong>Size:</strong> {item.acrylic_size_name}</p>
                          <p><strong>Thickness:</strong> {item.acrylic_thickness_name}</p>
                          <p><strong>Price:</strong> ₹{item.acrylic_price}</p>
                          <p><strong>Acrylic Preview Image:</strong><img
                            src={item.acrylic_final_preview}
                            alt="Acrylic Preview"
                            width="200"
                            className="mt-2"
                          /></p>
                          <p><strong>Quantity:</strong> {item.quantity}</p>
                        </div>
                      ) : (
                        // Non-Acrylic Product Details
                        <div>
                          <h3 className="font-semibold text-lg">{item.product_details?.name}</h3>
                          <p>{item.product_details?.description}</p>
                          {item.product_details?.image && (
                            <img
                              src={item.product_details.image}
                              alt={item.product_details.name}
                              width="100"
                              className="mt-2"
                            />
                          )}
                          <p><strong>Quantity:</strong> {item.quantity}</p>
                        </div>
                      )}
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default MyOrder;



{/* <div className="mx-14 my-5 flex gap-3">
        <input
          onChange={(e) => setinputorderid(e.target.value)}
          placeholder="Enter OrderId"
          className="border py-2 px-2 rounded-md"
          type="text"
        />
        <button onClick={handleInputBox} className="btn bg-blue-900 text-white">
          Search Order
        </button>
      </div> */}
