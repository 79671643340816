// import React from 'react'
// import Header from './Header'
// import './PlaceOrder.css'
// import { Link } from 'react-router-dom'
// import Footer from './Footer'
// const PlaceOrder = () => {
//     return (
//         <>
//             <Header />
//             <div className="container21">
//                 <div className="message">
//                     <div className="message-content">
//                     Thank you! Your order has been successfully placed.<br/> You will receive an email and SMS from our delivery partner containing the order ID,<br/> which you can use to track your order.
//                     </div>
//                 </div>
               
//                <Link to='/'> <button className="button">Back To Home Page</button></Link>
//             </div>
//             <Footer/>
//         </>
//     )
// }

// export default PlaceOrder
import React, { useState, useEffect } from 'react';
import Header from './Header'
import './PlaceOrder.css'
import { Link } from 'react-router-dom'
import Footer from './Footer'
const OrdersComponent = () => {
    useEffect(() => {
        fetchOrderId();
    }, []);
    const [cusOrderId, setCusOrderId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token'); // Fetch token from localStorage

    if (!token) {
      console.error('Token is required but not found.');
      return;
    }
    
    const fetchOrderId = async () => {
        try {
            const response = await fetch('https://api.hirdayam.com/api/getOrders', {
                headers: {
                    Authorization: `Bearer ${token}`, // Replace with actual token if required
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch order data: ${response.status} ${response.statusText}`);
            }

            const result = await response.json();

            // Assuming the order data is within `result.data.data`
            const orders = result.data.data;
            const orderWithCusId = orders.find(order => order.cus_order_id !== null);

            if (orderWithCusId) {
                setCusOrderId(orderWithCusId.cus_order_id);
            } else {
                setError("No order found with a valid 'cus_order_id'");
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

 

    return (
        <>
            <Header />
            <div className="container21">
                <div className="message">
                    <div className="message-content">
                        Thank you! Your order <strong>{cusOrderId}</strong> has been successfully placed.<br />
                        {cusOrderId ? (
                            <>Your order ID is <strong>{cusOrderId}</strong>. You can use this ID to track your order.</>
                        ) : (
                            <>No order ID available for tracking.</>
                        )}
                    </div>
                </div>
                <Link to='/'>
                    <button className="button">Back To Home Page</button>
                </Link>
            </div>
            <Footer />
        </>
    );
};

export default OrdersComponent;
