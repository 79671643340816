// import React, { useState ,useEffect} from 'react';
// import axios from 'axios';
// import '../Signup.css'
// import Header from '../Header';
// import Footer from '../Footer';
// const ManageAddress = () => {
//     const [addressType, setAddressType] = useState('home');
//     const [address, setAddress] = useState('');
//     const [latitude, setLatitude] = useState('');
//     const [longitude, setLongitude] = useState('');
//     const [addressId, setAddressId] = useState('');
//     const [zipCode, setZipCode] = useState('');
//     const [areaName, setAreaName] = useState('');
//     const [landmark, setLandmark] = useState('');
//     const [floorNumber, setFloorNumber] = useState('');
//     const [houseName, setHouseName] = useState(''); // Add state for house_name
//     const [success, setSuccess] = useState('');
//     const [error, setError] = useState('');

//     useEffect(() => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//               (position) => {
//                 // Successfully obtained position
//                 setLatitude(position.coords.latitude);
//                 setLongitude(position.coords.longitude);
//               },
//               (err) => {
//                 // Handle error
//                 setError(err.message);
//               }
//             );
//           } else {

//           }

//     }, [])


//     const handleAddressSubmit = async (event) => {
//         event.preventDefault();
//         try {
//             const token = localStorage.getItem('token');
//             const userId = localStorage.getItem('user_id');
//             if (!token) {
//                 throw new Error('No token found in localStorage.');
//             }
//             if (!userId) {
//                 throw new Error('No user_id found in localStorage.');
//             }

//             const addressData = {
//                 address_type: addressType,
//                 address: address,
//                 latitude: latitude || '',
//                 longitude: longitude || '',
//                 address_id: addressId || null,
//                 zip_code: zipCode || '',
//                 area_name: areaName || '',
//                 landmark: landmark || '',
//                 floor_number: floorNumber || '',
//                 house_name: houseName || '' // Include house_name here
//             };

//             console.log('Address Data:', addressData);

//             const response = await axios.post('https://api.hirdayam.com/api/Manageaddress', addressData, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             console.log('Address API response:', response.data);
//             setSuccess('Address managed successfully.');
//             setError('');
//         } catch (error) {
//             console.error('Error during address submission:', error);

//             if (error.response) {
//                 console.error('Server responded with:', error.response.data);
//                 setError(`Address submission failed: ${error.response.data.message || 'Unknown error'}`);
//             } else {
//                 setError('Address submission failed. Please try again.');
//             }

//             setSuccess('');
//         }
//     };


//     const fetchAddress = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             if (!token) {
//                 throw new Error('No token found in localStorage.');
//             }

//             const response = await axios.get('https://api.hirdayam.com/api/getAddresses', {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             console.log('Address fetch success:', response.data);

//             const addressData = response.data.data[0];  // Assuming [0] is the address you need

//             if (addressData) {
//                 setAddressId(addressData._id);  // Store the address ID
//                 setAddress(addressData.house_name);
//                 setLandmark(addressData.landmark);
//                 setZipCode(addressData.zip_code);
//                 setAreaName(addressData.area_name);
//                 setFloorNumber(addressData.floor_number);
//             } else {
//                 setAddressId(null);  // No existing address found
//             }

//         } catch (error) {
//             console.error('Error during address fetching:', error);
//             if (error.response) {
//                 console.error('Server responded with:', error.response.data);
//             } else {
//                 console.error('Address fetching failed. Please try again.');
//             }
//         }
//     };

//     return (
//         <>
//           <Header/>
//           <div className="login-form-container mt-24 md:mt-3 ">

//             <h2 className='login-heading'>Manage Address</h2>
//             <form onSubmit={handleAddressSubmit}>
//                 <div className="input-container">
//                     <label htmlFor="address_type">Address Type:</label>
//                     <select
//                         id="address_type"
//                         value={addressType}
//                         onChange={(e) => setAddressType(e.target.value)}
//                     >
//                         <option value="home">Home</option>
//                         <option value="work">Work</option>
//                     </select>
//                 </div>
//                 <div className="input-container">
//                     <label htmlFor="house_name">House Name:</label>
//                     <input
//                         type="text"
//                         id="house_name"
//                         name="house_name"
//                         placeholder="Enter house name"
//                         value={houseName}
//                         onChange={(e) => setHouseName(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="input-container">
//                     <label htmlFor="address">Address:</label>
//                     <input
//                         type="text"
//                         id="address"
//                         name="address"
//                         placeholder="Enter address"
//                         value={address}
//                         onChange={(e) => setAddress(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="input-container">
//                     <label htmlFor="area_name">Area Name:</label>
//                     <input
//                         type="text"
//                         id="area_name"
//                         name="area_name"
//                         placeholder="Enter area name"
//                         value={areaName}
//                         onChange={(e) => setAreaName(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="input-container">
//                     <label htmlFor="landmark">Landmark:</label>
//                     <input
//                         type="text"
//                         id="landmark"
//                         name="landmark"
//                         placeholder="Enter landmark"
//                         value={landmark}
//                         onChange={(e) => setLandmark(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="input-container">
//                     <label htmlFor="floor_number">Floor Number:</label>
//                     <input
//                         type="text"
//                         id="floor_number"
//                         name="floor_number"
//                         placeholder="Enter floor number"
//                         value={floorNumber}
//                         onChange={(e) => setFloorNumber(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="input-container">
//                     <label htmlFor="zip_code">ZIP Code:</label>
//                     <input
//                         type="text"
//                         id="zip_code"
//                         name="zip_code"
//                         placeholder="Enter ZIP code"
//                         value={zipCode}
//                         onChange={(e) => setZipCode(e.target.value)}
//                         required
//                     />
//                 </div>
//                 {success && <div style={{ color: 'green' }}>{success}</div>}
//                 {error && <div style={{ color: 'red' }}>{error}</div>}
//                 <div className=' flex justify-center'>
//                     <button className='btn bg-blue-800 w-1/2 text-white' type="submit">Submit Address</button>
//                 </div>

//             </form>

//         </div>
//      <Footer/>
//     </>

//     );
// };

// export default ManageAddress;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../Signup.css';
// import Header from '../Header';
// import Footer from '../Footer';
// import { useNavigate } from 'react-router-dom';
// const ManageAddress = () => {
//     const [addressType, setAddressType] = useState('home');
//     const [address, setAddress] = useState('');
//     const [latitude, setLatitude] = useState('');
//     const [longitude, setLongitude] = useState('');
//     const [addressId, setAddressId] = useState(''); // Used to store address ID if it exists
//     const [zipCode, setZipCode] = useState('');
//     const [areaName, setAreaName] = useState('');
//     const [landmark, setLandmark] = useState('');
//     const [floorNumber, setFloorNumber] = useState('');
//     const [houseName, setHouseName] = useState('');
//     const [success, setSuccess] = useState('');
//     const [error, setError] = useState('');

//     // Fetch geolocation on component mount
//     useEffect(() => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     setLatitude(position.coords.latitude);
//                     setLongitude(position.coords.longitude);
//                 },
//                 (err) => {
//                     setError(err.message);
//                 }
//             );
//         }
//     }, []);
//  const navigate = useNavigate();
//     // Fetch address data if available
//     useEffect(() => {
//         fetchAddress();
//     }, []);

//     const handleAddressSubmit = async (event) => {
//         event.preventDefault();
//         try {
//             const token = localStorage.getItem('token');
//             const userId = localStorage.getItem('user_id');
//             if (!token) {
//                 throw new Error('No token found in localStorage.');
//             }
//             if (!userId) {
//                 throw new Error('No user_id found in localStorage.');
//             }

//             const addressData = {
//                 address_type: addressType,
//                 address: address,
//                 latitude: latitude || '',
//                 longitude: longitude || '',
//                 address_id: addressId || null,  // Address ID for updates
//                 zip_code: zipCode || '',
//                 area_name: areaName || '',
//                 landmark: landmark || '',
//                 floor_number: floorNumber || '',
//                 house_name: houseName || ''  // Include house_name here
//             };

//             console.log('Address Data:', addressData);

//             const response = await axios.post('https://api.hirdayam.com/api/Manageaddress', addressData, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             console.log('Address API response:', response.data);
//             setSuccess('Address managed successfully.');
//             setError('');
//         } catch (error) {
//             console.error('Error during address submission:', error);
//             if (error.response) {
//                 console.error('Server responded with:', error.response.data);
//                 setError(`Address submission failed: ${error.response.data.message || 'Unknown error'}`);
//             } else {
//                 setError('Address submission failed. Please try again.');
//             }
//             setSuccess('');
//         }
//     };

//     // Function to fetch existing address
//     const fetchAddress = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             if (!token) {
//                 throw new Error('No token found in localStorage.');
//             }

//             const response = await axios.get('https://api.hirdayam.com/api/getAddresses', {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             console.log('Address fetch success:', response.data);

//             const addressData = response.data.data[0];  // Assuming [0] is the address you need

//             if (addressData) {
//                 setAddressId(addressData._id);  // Store the address ID
//                 setHouseName(addressData.house_name);  // Populate house name field
//                 setAddress(addressData.address);  // Populate address field
//                 setLandmark(addressData.landmark);  // Populate landmark field
//                 setZipCode(addressData.zip_code);  // Populate zip code field
//                 setAreaName(addressData.area_name);  // Populate area name field
//                 setFloorNumber(addressData.floor_number);  // Populate floor number field
//             } else {
//                 setAddressId(null);  // No existing address found
//             }

//         } catch (error) {
//             console.error('Error during address fetching:', error);
//             if (error.response) {
//                 console.error('Server responded with:', error.response.data);
//             } else {
//                 console.error('Address fetching failed. Please try again.');
//             }
//         }
//     };
//     const handleGoToCheckout = () => {
//         navigate('/checkout'); // Redirect to the checkout page
//     };

//     return (
//         <>
//             <Header />
//             <div className="login-form-container mt-24 md:mt-3">
//                 <h2 className='login-heading'>Manage Address</h2>
//                 <form onSubmit={handleAddressSubmit}>
//                     <div className="input-container">
//                         <label htmlFor="address_type">Address Type:</label>
//                         <select
//                             id="address_type"
//                             value={addressType}
//                             onChange={(e) => setAddressType(e.target.value)}
//                         >
//                             <option value="home">Home</option>
//                             <option value="work">Work</option>
//                         </select>
//                     </div>
//                     <div className="input-container">
//                         <label htmlFor="house_name">Address:</label>
//                         <input
//                             type="text"
//                             id="house_name"
//                             name="house_name"
//                             placeholder="Enter house name"
//                             value={houseName}
//                             onChange={(e) => setHouseName(e.target.value)}
//                             required
//                         />
//                     </div>
//                     {/* <div className="input-container">
//                         <label htmlFor="address">Address:</label>
//                         <input
//                             type="text"
//                             id="address"
//                             name="address"
//                             placeholder="Enter address"
//                             value={address}
//                             onChange={(e) => setAddress(e.target.value)}
//                             required
//                         />
//                     </div> */}
//                     <div className="input-container">
//                         <label htmlFor="area_name">Area Name:</label>
//                         <input
//                             type="text"
//                             id="area_name"
//                             name="area_name"
//                             placeholder="Enter area name"
//                             value={areaName}
//                             onChange={(e) => setAreaName(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <div className="input-container">
//                         <label htmlFor="landmark">Landmark:</label>
//                         <input
//                             type="text"
//                             id="landmark"
//                             name="landmark"
//                             placeholder="Enter landmark"
//                             value={landmark}
//                             onChange={(e) => setLandmark(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <div className="input-container">
//                         <label htmlFor="floor_number">Floor Number:</label>
//                         <input
//                             type="text"
//                             id="floor_number"
//                             name="floor_number"
//                             placeholder="Enter floor number"
//                             value={floorNumber}
//                             onChange={(e) => setFloorNumber(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <div className="input-container">
//                         <label htmlFor="zip_code">ZIP Code:</label>
//                         <input
//                             type="text"
//                             id="zip_code"
//                             name="zip_code"
//                             placeholder="Enter ZIP code"
//                             value={zipCode}
//                             onChange={(e) => setZipCode(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <div className='flex justify-center'>
//                         <button className='btn bg-blue-800 w-1/2 text-white' type="submit">
//                             Submit Address
//                         </button>
//                     </div>
//                     {success && (
//                         <div className="flex justify-center mt-3">
//                             <button onClick={handleGoToCheckout} className="btn bg-blue-800 w-1/2 text-white">
//                                 Go to Checkout
//                             </button>
//                         </div>
//                     )}
//                     {success && <div style={{ color: 'green' }}>{success}</div>}
//                     {error && <div style={{ color: 'red' }}>{error}</div>}
//                     {/* <div className=' flex justify-center'>
//                         <button className='btn bg-blue-800 w-1/2 text-white' type="submit">
//                             Submit Address
//                         </button>
//                     </div> */}
//                 </form>
//             </div>
//             <Footer />
//         </>
//     );
// };

// export default ManageAddress;
// import React from 'react';
// import Header from '../Header';
// import Footer from '../Footer';
// function AddressForm() {
//   return (
//     <>
//       <Header />
//       <div className="container mx-auto p-6">
//         <div className="bg-gray-200 p-8 rounded-lg shadow-lg">

//           <div className="flex gap-8 mb-6">
//             <button className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Home</button>
//             <button className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Work</button>
//             <button className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Others</button>
//           </div>

//           <div className="grid grid-cols-2 gap-6">
//             <div className="form-group">
//               <label htmlFor="contactPersonName" className="block text-gray-700 text-sm font-bold mb-2">
//                 Address
//               </label>
//               <input type="text" id="contactPersonName" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//             </div>

//             <div className="form-group">
//               <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
//                 Area Name
//               </label>
//               <input type="text" id="phone" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//             </div>

//             <div className="form-group">
//               <label htmlFor="city" className="block text-gray-700 text-sm font-bold mb-2">
//                 Landmark
//               </label>
//               <input type="text" id="city" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//             </div>

//             <div className="form-group">
//               <label htmlFor="state" className="block text-gray-700 text-sm font-bold mb-2">
//                 Floor Number
//               </label>
//               <input type="text" id="state" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//             </div>

//             <div className="form-group">
//               <label htmlFor="zip" className="block text-gray-700 text-sm font-bold mb-2">
//                 Zip Code
//               </label>
//               <input type="text" id="zip" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//             </div>
//           </div>
//           <div className='flex justify-center'>
//             <button className='btn bg-gray-800 w-1/2 text-white' type="submit">
//               Submit Address
//             </button>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default AddressForm;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

function AddressForm() {
  const [addressType, setAddressType] = useState('home');
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [addressId, setAddressId] = useState(''); // Used to store address ID if it exists
  const [zipCode, setZipCode] = useState('');
  const [areaName, setAreaName] = useState('');
  const [landmark, setLandmark] = useState('');
  const [floorNumber, setFloorNumber] = useState('');
  const [houseName, setHouseName] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch geolocation on component mount
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setError(err.message);
        }
      );
    }
  }, []);

  // Fetch address data if available
  useEffect(() => {
    fetchAddress();
  }, []);

  const handleAddressSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('user_id');
      if (!token) throw new Error('No token found in localStorage.');
      if (!userId) throw new Error('No user_id found in localStorage.');

      const addressData = {
        address_type: addressType,
        address: address,
        latitude: latitude || '',
        longitude: longitude || '',
        address_id: addressId || null,
        zip_code: zipCode || '',
        area_name: areaName || '',
        landmark: landmark || '',
        floor_number: floorNumber || '',
        house_name: houseName || ''
      };

      const response = await axios.post('https://api.hirdayam.com/api/Manageaddress', addressData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccess('Address managed successfully.');
      setError('');
    } catch (error) {
      setError('Address submission failed. Please try again.');
      setSuccess('');
    }
  };

  const fetchAddress = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found in localStorage.');

      const response = await axios.get('https://api.hirdayam.com/api/getAddresses', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const addressData = response.data.data[0];
      if (addressData) {
        setAddressId(addressData._id);
        setHouseName(addressData.house_name);
        setAddress(addressData.address);
        setLandmark(addressData.landmark);
        setZipCode(addressData.zip_code);
        setAreaName(addressData.area_name);
        setFloorNumber(addressData.floor_number);
      } else {
        setAddressId(null);
      }
    } catch (error) {
      console.error('Error during address fetching:', error);
    }
  };

  const handleGoToCheckout = () => {
    navigate('/checkout');
  };
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const getButtonClasses = (type) => {
    return addressType === type 
      ? 'bg-blue-600 text-white font-bold py-2 px-4 rounded'  // Active state styles
      : 'bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'; // Default styles
  };
  return (
    <>
      <Header />
      <div className="container mx-auto p-6">
        <div className="bg-gray-200 p-8 rounded-lg shadow-lg">
        <button 
  onClick={handleBack} 
  className="bg-[#23387A] text-white font-bold py-2 px-4 rounded mb-4 flex items-center justify-center"
>
  <i className="fas fa-arrow-left"></i>
</button>

          {/* <div className="flex gap-8 mb-6">
            <button className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={() => setAddressType('home')}>Home</button>
            <button className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={() => setAddressType('work')}>Work</button>
            <button className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={() => setAddressType('others')}>Others</button>
          </div> */}
          <div className="flex gap-8 mb-6">
      <button 
        className={getButtonClasses('home')} 
        onClick={() => setAddressType('home')}
      >
        Home
      </button>

      <button 
        className={getButtonClasses('work')} 
        onClick={() => setAddressType('work')}
      >
        Work
      </button>

      <button 
        className={getButtonClasses('others')} 
        onClick={() => setAddressType('others')}
      >
        Others
      </button>
    </div>

          <form onSubmit={handleAddressSubmit}>
            <div className="grid grid-cols-2 gap-6">
              <input type="text" value={houseName} onChange={(e) => setHouseName(e.target.value)} placeholder="Address" className="input" />
              <input type="text" value={areaName} onChange={(e) => setAreaName(e.target.value)} placeholder="Area Name" className="input" />
              <input type="text" value={landmark} onChange={(e) => setLandmark(e.target.value)} placeholder="Landmark" className="input" />
              <input type="text" value={floorNumber} onChange={(e) => setFloorNumber(e.target.value)} placeholder="Floor Number" className="input" />
              <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="Zip Code" className="input" />
            </div>
            <div className='flex justify-center'>
              <button className='btn bg-gray-800 w-1/2 text-white' type="submit">
                Submit Address
              </button>
            </div>
          </form>
          {success && <p className="text-green-600 mt-4">{success}</p>}
          {error && <p className="text-red-600 mt-4">{error}</p>}
          {/* <div className='flex justify-center mt-4'>
            <button className='btn bg-blue-600 w-1/2 text-white' onClick={handleGoToCheckout}>
              Go to Checkout
            </button>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddressForm;
