// import React from "react";

// const ContactForm = () => {
//   return (
//     <>
//     <div className="flex items-center justify-center min-h-screen bg-gray-50">
//       <div className="grid md:grid-cols-2 grid-cols-1 gap-8 max-w-7xl mx-auto p-8">
//         {/* Left Side Image */}
//         <div className="flex justify-center items-center">
//           <img
//             src="https://i.pinimg.com/736x/0e/f8/b0/0ef8b08b7c3214a8d8df6212ede2a87d.jpg"
//             alt="Contact Illustration"
//             className="w-full max-w-md"
//           />
//         </div>

//         {/* Right Side Form */}
//         <div className="bg-white p-8 shadow-md rounded-lg">
//           <h2 className="text-2xl font-bold mb-6 text-center">Send us a message</h2>

//           <form>
//             {/* Name and Email */}
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//               <div>
//                 <label className="block mb-1 font-medium text-gray-700">Your name</label>
//                 <input
//                   type="text"
//                   placeholder="John Doe"
//                   className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 />
//               </div>
//               <div>
//                 <label className="block mb-1 font-medium text-gray-700">Email address</label>
//                 <input
//                   type="email"
//                   placeholder="johndoe@email.com"
//                   className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 />
//               </div>
//             </div>

//             {/* Phone and Subject */}
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//               <div>
//                 <label className="block mb-1 font-medium text-gray-700">Your phone</label>
//                 <input
//                   type="text"
//                   placeholder="Contact Number"
//                   className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 />
//               </div>
//               <div>
//                 <label className="block mb-1 font-medium text-gray-700">Subject</label>
//                 <input
//                   type="text"
//                   placeholder="Short title"
//                   className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 />
//               </div>
//             </div>

//             {/* Message */}
//             <div className="mb-4">
//               <label className="block mb-1 font-medium text-gray-700">Message</label>
//               <textarea
//                 rows="6"
//                 placeholder="Write your message here"
//                 className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//               ></textarea>
//             </div>

//             {/* Submit Button */}
//             <div className="text-center">
//               <button
//                 type="submit"
//                 className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-md shadow-md"
//               >
//                 Send Message
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// };

// export default ContactForm;
import React from 'react';
import './Contact.css'
import Header from './Header';
import Footer from './Footer';
const GetInTouch = () => {
  return (
    <>
      <Header/>
      <div className="contact-page">
        <div className="contact-form">
          <h2 className='text-3xl font-bold text-center'>Get In Touch</h2>
          <p>
          We’d love to hear from you! Whether you’re a savvy shopper,<br/> a style enthusiast, or simply have a question, our dedicated team is here to support you.
          </p>
          
        </div>

        <div className="contact-section">
          <div className="contact-card">
            <div className="icon5">
              <i className="fas fa-globe"></i>
            </div>
            <h3 className='address'>Our Address</h3>
            <p className='para'>Lig Square, Anurag Nagar ,Indore (MP)</p>
            <p className='para'>Industrial area</p>
          </div>

          <div className="contact-card">
            <div className="icon5">
              <i className="fas fa-headset"></i>
            </div>
            <h3 className='address'>Contact Us</h3>
            <p className='para'>Feel free to reach out to us at:</p>
            <p className='para'>7987789150, 7909648684</p>
          </div>

          <div className="contact-card">
            <div className="icon5">
              {/* <i className="fas fa-calendar-alt"></i> */}
              <i className="fas fa-envelope"></i>
            </div>
            <h3 className='address'>Email Us</h3>
            <p className='para' >Drop us an email for any queries:</p>
            <p className='para'>hridayam04it@gmail.com</p>
            <p>
              {/* Fri: <span className="off-day">Off - Day</span> */}
            </p>
          </div>
        </div>
      </div>
     <Footer/>
    </>
  );
};

export default GetInTouch;
